import '../../static/fonts/Poppins/stylesheet.css';
import React, { useContext } from 'react';
import styled from 'styled-components';
import tempoToSymbol from '../../static/logo/tempo-to-symbol.svg';
import { BrandContext } from '../ContextProvider';

const LogoContainer = styled.div`
  width: 200px;
  margin-right: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    width: 150px;
  }
`;

const Img = styled.img`
  display: block;
  width: 38px;
  height: auto;
  margin: 10px;
  object-fit: contain;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    max-width: 150px;
  }
`;

const BrandName = styled.p`
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};
  strong {
    font-size: 35px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
`;

const VersionNumber = styled.span`
  position: absolute;
  bottom: -2px;
  right: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 12px;
  color: #a2a2a2;
`;

const BrandLogo = () => {
  const brandContext = useContext(BrandContext);
  const { brandNameTuple: name } = brandContext;
  const vNumber = '0.9.7.0';

  return (
    <LogoContainer>
      <Img src={tempoToSymbol} />
      <BrandName>
        <strong>{name[0]}</strong>.{name[1]}
        <VersionNumber>{vNumber}</VersionNumber>
      </BrandName>
    </LogoContainer>
  );
};

export default BrandLogo;
