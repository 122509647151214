import styled from 'styled-components';

type Props = {
  flex?: boolean;
  grid?: boolean;
  center?: boolean;
  columns?: string;
  gap?: string;
  justifyContent?: string;
  alignItems?: string;
  rows?: string;
  padding?: string;
  paddingTop?: string;
  relative?: boolean;
  paddingBottom?: string;
  paddingRight?: string;
  paddingLeft?: string;
  margin?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  marginBottom?: string;
  background?: string;
  color?: string;
  width?: string;
  full?: boolean;
  ver?: boolean;
  mobCol?: string;
  mobRows?: string;
  height?: string;
};

export const Wrapper = styled.div<Props>`
  display: ${props => (props.flex ? 'flex' : props.grid ? 'grid' : 'block')};
  justify-content: ${props => (props.center ? 'center' : 'initial')};
  min-height: ${props => (props.full ? '100vh' : '')};
  flex-direction: ${props => (props.ver ? 'column' : 'row')};
  width: ${props => props.width};
  height: ${({ height }) => height};
  justify-content: ${props => props.justifyContent};
  position: ${props => (props.relative ? 'relative' : 'initial')};
  align-items: ${props => (props.center ? 'center' : 'initial')};
  align-items: ${props => props.alignItems};
  grid-template-columns: ${props => (props.grid ? props.columns : '')};
  grid-template-rows: ${props => (props.grid ? props.rows : '')};
  grid-gap: ${props => props.gap};
  padding: ${props => props.padding};
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  padding-right: ${props => props.paddingRight};
  padding-left: ${props => props.paddingLeft};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  background: ${props => props.background || props.theme.colors.white};
  color: ${props => props.color};
  @media (max-width: 768px) {
    grid-template-columns: ${props => props.mobCol};
    grid-template-rows: ${props => props.mobRows};
  }
`;
