import { isDev } from '../utils';

export const MAIN_HOST =
  process.env.REACT_APP_ENV === 'production'
    ? window.origin
    : 'http://localhost:8000';

export const GOOGLE_CLIENT_ID_PROD =
  '1030968281586-k8teiqosnef119s012bcvb1rg3kuu5q2.apps.googleusercontent.com';

export const IP_API = 'd62b86d56bbf08693a349bb30a633e0d';
export const GOOGLE_CLIENT_ID_PROD_1 =
  '139521379912-osems96io9lseetgof6jngc282shr307.apps.googleusercontent.com';
export const GOOGLE_CLIENT_DEV =
  '925994554237-5lp5ec08q1j00fit9lrsnevgqi6tg6ci.apps.googleusercontent.com';

export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_ENV !== 'production'
    ? GOOGLE_CLIENT_DEV
    : GOOGLE_CLIENT_ID_PROD;
export const s3Config = {
  bucketName: 'timerapptestt',
  region: 'us-east-1',
  accessKeyId: 'AKIAI4VGTVQVUY52KV4Q',
  secretAccessKey: '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'
};

export const USER_BACK_SCRIPT = `
Userback = window.Userback || {};
      Userback.access_token =
        '6450|9416|x7yqt8SH5QgALxEBwLjvrdQFY5kYKEgf5IGNy2WWmp66LvqaNT';

      (function(id) {
        if (document.getElementById(id)) {
          return;
        }
        var s = document.createElement('script');
        s.id = id;
        s.async = 1;
        s.src = 'https://static.userback.io/widget/v1.js';
        var parent_node = document.head || document.body;
        parent_node.appendChild(s);
      })('userback-sdk');
`;

export const HELP_CHURCH_1 = ` (function(w, d) {
        w.HelpCrunch = function() {
          w.HelpCrunch.q.push(arguments);
        };
        w.HelpCrunch.q = [];
        function r() {
          var s = document.createElement('script');
          s.async = 1;
          s.type = 'text/javascript';
          s.src = 'https://widget.helpcrunch.com/';
          (d.body || d.head).appendChild(s);
        }
        if (w.attachEvent) {
          w.attachEvent('onload', r);
        } else {
          w.addEventListener('load', r, false);
        }
      })(window, document);`;

export const HELPCHURCH_2 = `   HelpCrunch('init', 'pragmidea', {
        applicationId: 1,
        applicationSecret:
          'VdBfg6j+O+unkhxx81OcGjuUQSxopOVVbYXoYdTgpBgC4AOdn6Afrsrf96Nw+OcBS8w8vr6oIwA6FDHsmDUR/g=='
      });

      HelpCrunch('showChatWidget');`;

export const HELPCHURCH_UNLOAD = `   HelpCrunch('init', 'pragmidea', {
        applicationId: 1,
        applicationSecret:
          'VdBfg6j+O+unkhxx81OcGjuUQSxopOVVbYXoYdTgpBgC4AOdn6Afrsrf96Nw+OcBS8w8vr6oIwA6FDHsmDUR/g=='
      });

      HelpCrunch('hideChatWidget');`;
