import { Button as EvergreenButton } from 'evergreen-ui';
import styled from 'styled-components';

type Props = {
  marginTop?: string | number;
  marginLeft?: string | number;
  circular?: boolean;
  marginBottom?: string | number;
  outline?: boolean;
  marginRight?: string | number;
  appearance?:
    | 'primary'
    | 'accent'
    | 'secondary'
    | 'danger'
    | 'alert'
    | 'info'
    | 'white';
  width?: string;
};

const colorMapper = (appearance: string, theme: any) => {
  switch (appearance) {
    case 'primary':
      return theme.colors.primary;
    case 'accent':
      return theme.colors.accent;
    case 'danger':
      return theme.colors.danger;
    case 'secondary':
      return theme.colors.black;
    case 'alert':
      return theme.colors.alert;
    case 'info':
      return theme.colors.info;
    default:
      return theme.colors.gray;
  }
};

export const Button = styled.button<Props>`
  display: flex;
  outline: none;
  border: ${({ circular }) => (circular ? '1px solid transparent' : 'initial')};
  border-radius: ${({ circular }) => (circular ? '100%' : 'initial')};
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  min-width: 50px;
  width: ${({ width }) => width};
  height: 40px;
  padding: 5px 15px;
  border: 2px solid transparent;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 400;
  letter-spacing: 0.07rem;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-top: ${({ marginTop = 0 }) => String(marginTop) + 'px'};
  margin-left: ${({ marginLeft = 0 }) => String(marginLeft) + 'px'};
  margin-bottom: ${({ marginBottom = 0 }) => String(marginBottom) + 'px'};
  margin-right: ${({ marginRight = 0 }) => String(marginRight) + 'px'};
  ${({ theme, appearance = 'primary', outline = false }) => {
    const color = colorMapper(appearance, theme);

    if (!outline) {
      if (appearance === 'white') {
        return `
          background-color: #FFF;
          color: #000;
          border: 2px solid transparent;
          &:hover {
            cursor: pointer;
            background-color: #e6e6e6;
          }
        `;
      }
      return `
        background-color: ${color};
        color: #fff;
        border: 2px solid transparent;
        &:hover {
          border: 2px solid ${color};
          cursor: pointer;
          background-color: #ffffff;
          color: ${color};
        }
      `;
    } else {
      return `
        background-color: #fff;
        color: ${color};
        border: 2px solid ${color};
        &:hover {
          cursor: pointer;
          background-color: ${color};
          color: #fff;
        }
      `;
    }
  }}
`;

export default Button;
export { EvergreenButton };
