import { Checkbox } from 'evergreen-ui';
import styled from 'styled-components';

const CustomCheckbox = styled(Checkbox)`
  & > span {
    color: ${({ theme }) =>
      theme.themeName === 'DARK' ? theme.colors.black : '#425a70'};
  }
`;

export default Checkbox;
