import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { withApollo } from 'react-apollo';
import { CLIENT_USER } from '../features/User/graphql';

const LogRocketRegisterer = ({
  userId,
  client
}: {
  userId: string;
  client?: any;
}) => {
  const registerUserOnLogRocket = async () => {
    const { data } = await client.query({
      query: CLIENT_USER,
      variables: { userId }
    });
    if (!data.userByClientId) {
      LogRocket.identify(userId, {
        name: 'Anonymous User',
        email: 'Non Registered User',
        subscriptionType: 'not regisered yet'
      });
    } else {
      const { email, name } = data.userByClientId;
      LogRocket.identify(userId, {
        name,
        email,
        // Add your own custom user variables here, ie:
        subscriptionType: 'regisered'
      });
    }
  };

  useEffect(() => {
    registerUserOnLogRocket();
  }, [userId]);

  return null;
};

export default withApollo(LogRocketRegisterer);
