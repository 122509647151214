import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { adopt } from 'react-adopt';
import { Mutation, MutationFn } from 'react-apollo';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { UPDATE_TIMER as CREATE_TIMER_MUTATION } from '../../../features/TimerPod/graphql';
import { saveToLocalStoarge } from '../../../store/localStorage';
import { generateId } from '../../../utils/generalUtils';
import { createTimer, createTimerPodObject, getTimeZone } from '../utils';
import { USER_TEMPLATES } from '../../../components/Header/graphql';

const CREATE_TIMERPOD_MUTATION = gql`
  mutation TimerPodDynamicCreate($timerPod: TimerPodInput!) {
    timerPodDynamicCreate(timerPod: $timerPod) {
      timerPodId
    }
  }
`;

interface ICreateTimerPodContainer {
  // timerPodId: string;
  user: any;
  template: ID;
  isAuthenticated?: boolean;
  createTimerMutation: MutationFn;
  createTimerPodMutation: MutationFn;
  userId: string;
}

const CreateTimerPodDataProvider = ({
  userId,
  template,
  user
}: {
  userId: string;
  template: string;
  user: any;
}) => {
  const Composed = adopt({
    createTimerMutation: ({ render }) => (
      <Mutation mutation={CREATE_TIMER_MUTATION}>{render}</Mutation>
    ),
    createTimerPodMutation: ({ render }) => (
      <Mutation mutation={CREATE_TIMERPOD_MUTATION}>{render}</Mutation>
    )
  });
  return (
    <Composed>
      {({ createTimerMutation, createTimerPodMutation }) => (
        <CreateTimerPodContainer
          userId={userId}
          user={user}
          template={template}
          createTimerMutation={createTimerMutation}
          createTimerPodMutation={createTimerPodMutation}
        />
      )}
    </Composed>
  );
};

const CreateTimerPodContainer = ({
  userId,
  user,
  createTimerMutation,
  createTimerPodMutation,
  template,
  isAuthenticated
}: ICreateTimerPodContainer) => {
  const [redirect, setRedirect] = useState<boolean>(false);
  const [timerPodId, setTimerPodId] = useState<string>('');

  const createTimerAndTimerPod = async () => {
    const timerId = generateId();
    const timezone = getTimeZone(user);
    const timer = createTimer({ timerId, userId, timezone });

    await createTimerMutation({
      variables: {
        timer,
        templateId: template
      }
    });

    const timerPod = createTimerPodObject({
      userId,
      templateId: template,
      timersId: [timer.timerId]
    });

    if (!user || !user.userId) {
      const userTimers: any[] =
        JSON.parse(localStorage.getItem('userTimers') as any) || [];
      const dataToSave: ITimerPodEntity = [timerPod, ...userTimers] as any;
      const lsData = JSON.stringify(dataToSave);
      saveToLocalStoarge('userTimers', lsData);
    } else {
      localStorage.removeItem('userTimers');
    }
    await createTimerPodMutation({
      variables: {
        timerPod
      },
      refetchQueries: [
        { query: USER_TEMPLATES, variables: { userId: userId, limit: 4 } }
      ]
    });
    setTimerPodId(timerPod.timerPodId);
    setRedirect(true);
  };

  useEffect(() => {
    createTimerAndTimerPod();
  }, []);

  if (redirect) {
    return <Redirect to={`/timer/${timerPodId}`} />;
  }

  return <h1>Loading....</h1>;
};

const mapStateToProps = ({auth}) => ({
  userId: auth.userId,
  user: auth.user,
  isAuthenticated: auth.isAuthenticated
});

export default connect(mapStateToProps)(CreateTimerPodDataProvider);
