import { FormField } from 'evergreen-ui';
import styled from 'styled-components';

const StyledFormField = styled(FormField)`
  label {
    color: ${({ theme }) => {
      return theme.themeName === 'DARK' ? theme.colors.black : '#425A7';
    }} !important;
  }
`;

export default StyledFormField;
