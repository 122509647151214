import React from 'react';
import styled from 'styled-components';

export const BasicLink = styled.p`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-family: ${({ theme }) => theme.fonts.primary};
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const SmallText = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 400px;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme, color }) => (color ? color : theme.colors.gray)};
`;

export const Paragraph = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: 28px;
  color: ${({ color, theme }) => color || theme.colors.dark};
`;

export const Heading1 = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 95px;
  color: ${({ color, theme }) => color || theme.colors.dark};
`;

export const Heading2 = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 80px;
  color: ${({ color, theme }) => color || theme.colors.dark};
`;

export const Heading3 = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 60px;
  color: ${({ color, theme }) => color || theme.colors.dark};
`;
export const Heading4 = styled.h4`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 400;
  font-size: 40px;
  color: ${({ color, theme }) => color || theme.colors.dark};
`;
export const Heading5 = styled.h5`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 400;
  font-size: 30px;
  color: ${({ color, theme }) => color || theme.colors.dark};
`;

export const Heading6 = styled.h6`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 20px;
  color: ${({ color, theme }) => color || theme.colors.dark};
`;
