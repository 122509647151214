export default {
  common: {
    signup: 'Sign Up',
    'start-timer': 'Start a new timer',
    save: 'Save',
    goback: 'Go Back',
    loading: 'Loading',
    'updated-successfully': 'Actualizdo Exitosamente'
  },
  navmenu: {
    'rooms-link': 'My Rooms',
    'create-timer': 'Create a new Timer',
    'create-room': 'Create Room',
    'create-template': 'New Template',
    'timers-link': 'My Timers',
    'show-all-templates': 'Show All Templates',
    'tasks-link': 'My Tasks',
    'settings-link': 'My Settings',
    login: 'Log In',
    'account-menu': {
      workspace: 'Workspace',
      stats: 'My Stats',
      features: 'Vote Features',
      signout: 'Sign Out'
    }
  },
  homepage: {
    'timers-completed': 'timers completed!',
    'start-adding': 'Start adding timers yourself!',
    'cta-button': 'Start a new timer',
    'intro-title': {
      'timer-to': 'Timer to ',
      with: 'with open ',
      collaboration: 'collaboration'
    }
  },
  accountsettings: {
    'tabs-names': {
      general: 'General',
      appearance: 'Appearance',
      locationtime: 'Location & Time',
      defaults: 'Defaults'
    },
    general: {
      'name-input': 'Enter Your Name',
      'company-input': 'Enter Your Company Name',
      'feedback-switch': 'Show the feedback button',
      'chat-switch': 'Show the chat button',
      'disturb-mode-switch': 'Turn on "Don\'t disturb" mode'
    },
    appearance: {
      'custom-image': 'Custom Image',
      gravatar: 'Gravatar',
      initials: 'Initials'
    },
    locationtime: {
      'choose-language': 'Choose Language',
      'choose-country': 'Choose your country',
      'choose-timezone': 'Choose your timezone'
    },
    defaults: {
      'timer-duration': 'Enter default timer duration',
      'background-image': 'Default Timers/Rooms Background Image',
      'upload-image-radio': 'Upload image',
      'search-for-image-radio': 'Search for image',
      'timer-rooms-music': 'Default Timers/Rooms Music',
      'pick-song': 'Pick Song',
      'add-new-song': 'Add new song'
    }
  },
  timerpod: {
    settingsmenu: {
      timer: 'Timer',
      appearance: 'Appearance',
      sound: 'Sound',
      archive: 'Archive'
    },
    taskselector: {
      'assign-task': 'Assign a task...'
    },
    settings: {
      title: 'Settings',
      tabs: {
        timer: 'Timer',
        appearance: 'Appearance',
        sound: 'Sound'
      }
    }
  },
  rooms: {
    userRooms: {
      'create-room': 'Create new room'
    }
  }
};
