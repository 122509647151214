import gql from 'graphql-tag';

export const USER_SUB = gql`
  subscription UserSub($userId: ID) {
    userSub(userId: $userId) {
      name
      userId
      company
      role
      lastSeen
      settings {
        showFeedback
        theme
        avatar
        noDisturb
        language
        location {
          country
          timezone
        }
        showChat
      }
      email
      userId
      token
    }
  }
`;
