import { createAction, handleActions } from 'redux-actions';
import primaryTheme from '../../styles/themes';
const payloadReducer = payload => payload;
// Actions
const getCurrentTheme: any = createAction(
  'Will get the user theme, if not any return the default',
  payloadReducer
);
const setTheme: any = createAction(
  'Set theme based on user preferences',
  payloadReducer
);

// Dispatchers
export const getCurrentThemeDispatcher = () => async (dispatch, getState) => {
  const currentTheme = getState().settings.theme.keys
    ? getState().settings.theme
    : primaryTheme;
  await dispatch(getCurrentTheme(currentTheme));
};

export const setThemeDispatcher = theme => async (dispatch, getState) => {
  await dispatch(setTheme(theme));
};

// Initial state
const initialState = {
  theme: {}
};

const settingReducer = handleActions<any>(
  {
    [getCurrentTheme]: (state, { payload }) => ({ ...state, theme: payload }),
    [setTheme]: (state, { payload }) => ({ ...state, theme: payload })
  },
  initialState
);

export default settingReducer;
