import React from 'react';

type Props = {
  solid?: boolean;
  icon: string;
  color?: string;
  size?: string;
  clickable?: boolean;
  far?: boolean;
  onClick?: Function;
};
export default ({ solid, color, clickable, size, far, icon }: Props) => (
  <i
    style={{
      color: `${color}`,
      cursor: `${clickable ? 'pointer' : ''}`,
      fontSize: `${size}`
    }}
    className={getClassName(far, solid, icon)}
  />
);

const getClassName = (far, solid, icon) => {
  if (far) {
    return `far fa-${icon}`;
  } else if (solid) {
    return `fas fa-${icon}`;
  }
  return `fa fa-${icon}`;
};
