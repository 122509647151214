import { playerSettings } from '../features/Room/__types__/types';

export default (startAt: number = 0, playing: boolean): playerSettings => ({
  height: '200',
  width: '200',
  playerVars: {
    autoplay: playing,
    start: startAt,
    controls: 0,
    fs: 0,
    loop: true
  }
});

export const parseYouTubeId = (url: string) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};
