import { getUserTimeZone } from './geo';
import isDevImport from './isDev';
import mainHostImport, { appLink, socketLink } from './mainhost';
export {
  SLACK_CODE_EXCHANGE,
  SLACK_CLIENT_ID,
  SLACK_CLIENT_SECRET
} from './mainhost';
export { logError } from './log';
import { uploadToS3 as uploadToS3Import } from './s3-upload';
export { deviceInfo } from './device-info.helper';
export { goBack } from './goBack';
export { logAnalytics } from './logAnalytic';
export { showNotification } from './notifications';
export { unregister } from './service-workers';
export { SERVER_API_KEY } from './mainhost';
export { toastify } from './toast';
import youtubeopt from './youtube';

export const uploadToS = uploadToS3Import;
export const isDev = isDevImport();
export const MAIN_HOST = mainHostImport();
export const APP_LINK = appLink();
export const SOCKET_LINK = socketLink();
export const USER_TIME_ZONE = getUserTimeZone();
export const youtubeOptions = youtubeopt;
