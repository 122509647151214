import { readAndCompressImage } from 'browser-image-resizer';
export default async fileData => {
  try {
    const config = {
      quality: 0.7,
      maxWidth: 500,
      maxHeight: 500
    };
    let resizedImage = await readAndCompressImage(fileData, config);
    return resizedImage;
  } catch (err) {
    console.error(err);
  }
};

export const resizer = async (fileData, width, height, quality) => {
  try {
    const config = {
      quality,
      maxWidth: width,
      maxHeight: height
    };
    let resizedImage = await readAndCompressImage(fileData, config);
    return resizedImage;
  } catch (err) {
    console.error(err);
  }
};
