import { distanceInWordsToNow } from 'date-fns';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import UserAvatar from 'react-avatar';
import { toast } from 'react-toastify';
import { USER_LOGS } from '../../../../graphql';
import {
  Spinner,
  FaIcon,
  Tooltip
} from '../../../../../../components/Elements';
import { logError } from '../../../../../../utils/log';
import Link from '../../../../../../components/Common/Link';
import {
  RoomLink,
  MainWrapper,
  IndicatorWrapper,
  Title,
  EventBox,
  TitleWrapper,
  EventDate,
  AvatarWrapper
} from './Elements';

type Props = {
  userId: string;
  closeWorkspaceHandler: Function;
};

const renderAvatar = (user: IUser) => {
  if (user.settings) {
    return (
      <UserAvatar
        name={user.name || 'Guest'}
        src={
          user.settings.avatar && user.settings.avatar.startsWith('http')
            ? user.settings.avatar
            : ''
        }
        round={true}
        size="30px"
      />
    );
  }
};

const renderRoomData = (event: IEvent, navigate: Function) => {
  if (event.room) {
    const { room } = event;
    return (
      <RoomLink onClick={_ => navigate()}>
        in{' '}
        <Link to={`/room/${room.roomId}`} text="" color="#4da6ff">
          {room.description || 'Room'}
        </Link>
      </RoomLink>
    );
  } else {
    return <RoomLink>as standalone timer</RoomLink>;
  }
};

const getEventIcon = (eventType: EventTypes) => {
  switch (eventType) {
    case 'COMPLETED_TIMER':
      return '#42f474';

    case 'CREATED_TIMER':
      return '#e26600';

    case 'TIMER_PAUSED':
      return '#f7ef09';

    case 'TIMER_STARTED':
      return '#003182';

    case 'ARCHIVED_TIMER':
      return 'red';

    default:
      break;
  }
};

const getEventText = (eventType: EventTypes) => {
  switch (eventType) {
    case 'COMPLETED_TIMER':
      return 'completed a timer';

    case 'CREATED_TIMER':
      return 'created a timer';

    case 'ARCHIVED_TIMER':
      return 'achived a timer';

    case 'TIMER_PAUSED':
      return 'paused a timer';

    case 'TIMER_STARTED':
      return 'started a timer';

    default:
      break;
  }
};

const renderEventText = (e: IEvent, userWatching: string) => {
  const text = getEventText(e.eventType);
  if (e.user && e.userId == userWatching) {
    return `You ${text} `;
  }
  const name = e.user ? e.user.name || 'Anonymous' : 'Anonymous';
  return `${name} ${text} `;
};

export default ({ userId, closeWorkspaceHandler }: Props) => {
  const [filteredData, setData] = useState<IEvent[] | null>(null);

  const filterEvents = (type: EventTypes | undefined, events: IEvent[]) => {
    const filtered: IEvent[] = type
      ? events.filter(e => e.eventType === type)
      : events;
    setData(filtered);
  };
  return (
    <Query
      query={USER_LOGS}
      variables={{ userId }}
      fetchPolicy={'cache-and-network'}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Spinner />;
        }
        if (error) {
          toast.error(error.message);
          logError(error);
          return <h1>{error.message}</h1>;
        }

        const { latestEvents } = data;
        const showedEvents = filteredData ? filteredData : latestEvents;
        return (
          <MainWrapper>
            {showedEvents && showedEvents.length !== 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: '10px'
                }}
              >
                <IndicatorWrapper
                  onClick={() => filterEvents(undefined, latestEvents)}
                >
                  <Tooltip content="all " position="top">
                    <FaIcon icon="square" color={'#fff'} />
                  </Tooltip>
                </IndicatorWrapper>
                <IndicatorWrapper
                  onClick={() => filterEvents('COMPLETED_TIMER', latestEvents)}
                >
                  <Tooltip content="completed " position="top">
                    <FaIcon icon="square" color={'#42f474'} />
                  </Tooltip>
                </IndicatorWrapper>
                <IndicatorWrapper
                  onClick={_ => filterEvents('CREATED_TIMER', latestEvents)}
                >
                  <Tooltip content="created " position="top">
                    <FaIcon icon="square" color={'orange'} />
                  </Tooltip>
                </IndicatorWrapper>
                <IndicatorWrapper
                  onClick={_ => filterEvents('TIMER_STARTED', latestEvents)}
                >
                  <Tooltip content="started " position="top">
                    <FaIcon icon="square" color={'#003182'} />
                  </Tooltip>
                </IndicatorWrapper>
                <IndicatorWrapper
                  onClick={_ => filterEvents('TIMER_PAUSED', latestEvents)}
                >
                  <Tooltip content="paused " position="top">
                    <FaIcon icon="square" color={'#f7ef09'} />
                  </Tooltip>
                </IndicatorWrapper>
                <IndicatorWrapper
                  onClick={_ => filterEvents('ARCHIVED_TIMER', latestEvents)}
                >
                  <Tooltip content="achived " position="top">
                    <FaIcon icon="square" color={'red'} />
                  </Tooltip>
                </IndicatorWrapper>
              </div>
            )}
            {showedEvents && showedEvents.length === 0 && (
              <Title>you have not showedEvents</Title>
            )}
            {showedEvents &&
              showedEvents.map((e: IEvent, index) => (
                <EventBox key={index}>
                  <TitleWrapper>
                    <FaIcon
                      icon="square"
                      color={`${getEventIcon(e.eventType)}`}
                    />
                    <Title>
                      {' '}
                      {renderEventText(e, userId)}
                      {renderRoomData(e, closeWorkspaceHandler)}
                    </Title>
                  </TitleWrapper>
                  {e.date && (
                    <EventDate>
                      {distanceInWordsToNow(+e.date, {
                        includeSeconds: false
                      })}{' '}
                      ago
                    </EventDate>
                  )}
                  <AvatarWrapper>{renderAvatar(e.user as any)}</AvatarWrapper>
                </EventBox>
              ))}
          </MainWrapper>
        );
      }}
    </Query>
  );
};
