import v4 from 'uuid/v4';

export const ObjectsAreEqual = (obj1: any, obj2: any) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const isObjectEmpty = object => !Object.keys(object).length;

export const generateId = () => v4().split('-')[0];

export const addIfNotInArrayTop = (newItem, array) => {
  if (array.indexOf(newItem) === -1) {
    let newArray = [newItem, ...array];
    return newArray;
  }
  return array;
};

export const addIfNotInArrayBottom = (newItem: any, array: any[]): any[] => {
  if (array.indexOf(newItem) === -1) {
    let newArray = [...array, newItem];
    return newArray;
  }
  return array;
};

export const matchesMedia = mediaQuery => {
  let mql = window.matchMedia(mediaQuery);
  return mql.matches;
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const moveElementInArray = (
  arr: any[],
  old_index: number,
  new_index: number
): any[] => {
  let passedArray = [...arr];
  while (old_index < 0) {
    old_index += passedArray.length;
  }
  while (new_index < 0) {
    new_index += passedArray.length;
  }
  if (new_index >= passedArray.length) {
    var k = new_index - passedArray.length + 1;
    while (k--) {
      passedArray.push(undefined);
    }
  }
  passedArray.splice(new_index, 0, passedArray.splice(old_index, 1)[0]);
  return [...passedArray];
};

export const updateBackground = (selector: string, stylesObject: any): void => {
  let element: HTMLDivElement = document.querySelector(
    selector
  ) as HTMLDivElement;
  if (element) {
    for (let key in stylesObject) {
      if (stylesObject.hasOwnProperty(key)) {
        element.style[key] = stylesObject[key];
      }
    }
  }
};

export const getDomainNameTuple = (): string[] => {
  const name = document.domain;
  if (name === 'localhost') return ['tempo', 'to'];
  let nameArray = name.split('.');
  if (nameArray.length <= 2) return nameArray;
  return nameArray.slice(1);
};

export const sort2DArrayArgument = (a, b) => a[0] - b[0] || a[1] - b[1];

export const uniqueResultOne = (
  array1: { id: any }[],
  array2: { id: any }[]
): { id: any }[] => {
  return array1.filter(function(obj) {
    return !array2.some(function(obj2) {
      return obj.id == obj2.id;
    });
  });
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};
