import styled from 'styled-components';

const PageWrapper = styled.div<{ s }>`
  width: 100%;
  padding: 100px 0 0 0;
  background: ${({ theme }) => theme.colors.white};
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding-top: 80px;
    padding-bottom: 0;
  }
`;

export default PageWrapper;
