import React, { createContext, useState } from 'react';
import Fullscreen from 'react-full-screen';

export const FullscreenContext = createContext({
  isEnabled: false,
  setIsEnabled: (value: boolean): any => {}
});
const FullscreenProvider = ({ children }) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  return (
    <Fullscreen
      enabled={isEnabled}
      onChange={isFullscreen => setIsEnabled(isFullscreen)}
    >
      <FullscreenContext.Provider
        value={{
          isEnabled,
          setIsEnabled
        }}
      >
        {children}
      </FullscreenContext.Provider>
    </Fullscreen>
  );
};

export default FullscreenProvider;
