import styled from 'styled-components';
import React from 'react';

const CellElement = styled.div`
  text-align: center;
  line-height: 40px;
`;

const HeaderElement = styled.div`
  padding: 10px;
  margin: 0px;
  width: 100%;
  height: 100%;
  color: ${props => props.theme.colors.primary};
`;

export const CellWrapper = ({ val }): JSX.Element => (
  <CellElement>{val}</CellElement>
);
export const HeaderWrapper = ({ val }): JSX.Element => (
  <HeaderElement>{val}</HeaderElement>
);

export const ButtonContainer = styled.div<{ size?: number }>`
  text-align: center;
  line-height: 40px;
  svg {
    width: ${({ size }) => (size ? String(size) + 'px' : '22px')};
    line-height: 40px;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: -5px;
    height: ${({ size }) => (size ? String(size) + 'px' : '22px')};
  }
  &:hover {
    cursor: pointer;
  }
`;
