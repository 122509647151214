import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
.tab-list {
  border-bottom: 1px solid #ddd;
}
button {
  position: relative;
}

.spotify-btn {
  padding: 10px 10px;
  background: #1DB954;
  cursor: pointer;
  color: #fff;
  border-radius: 15px;
  border: 1px solid #fff;
  outline: none;

}

.account-tab {
    background: transparent;
    border: 0;
    font-family: inherit;
    outline: none;
    cursor: pointer !important;
    align-items: flex-start;
    text-align: center;
    position: relative;
    cursor: default;
    color: buttontext;
    font-size: inherit;
    padding: 1rem 2rem;
    -webkit-transition: background .3s cubic-bezier(.22,.61,.36,1);
    transition: background .3s cubic-bezier(.22,.61,.36,1);
}
.selected-tab:after {
    bottom: 0px;
    content: "";
    top: 100%;
    left: 0px;
    position: absolute;
    width: 100%;
    border-bottom: 3px solid #0D5fe4;
}
  /* React Calendar Heatmap */
  .react-calendar-heatmap .color-scale-1 { fill: #d6e685; }
  .react-calendar-heatmap .color-scale-2 { fill: #8cc665; }
  .react-calendar-heatmap .color-scale-3 { fill: #44a340; }
  .react-calendar-heatmap .color-scale-4 { fill: #1e6823; }
  .td-table:nth-child(odd) {
    background: blue;
  }

  #component-error-text {
    color: red;
  }

  .overflowed {
    z-index: 99;
  }
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: sans-serif;
  }
  .table-header {
    background: red;
  }

    .Flipcard-flipper {
      /* width: 90vw; */
    /* display: flex;
    background: red;
    justify-content: center;
    grid-template-columns: 1fr; */
  }
  body, html, #root, .App {
    height: 100%;
  }

  /* IMPORTANT CSS CODE TO HIDE THE SIDEBAR FROM THE ATLASKIT DRAWER */
  .atlaskit-portal-container .atlaskit-portal > div:nth-child(2) > div:nth-child(1) {
    display: none;
  }

  /* Fullsreen mode CSS */
  .fullscreen {
    height: 100vh;
    /* overflow-y:scroll; */
  }
  .fullscreen-enabled {
    header.headerbar {
      display: none!important;
    }
    .timerpagewrapper {
      .BottomBar {
        display: none;
      }
      /* #timerpod-ham-menu {
        display: none;
      } */
    }
  }
`;

export default GlobalStyles;
