import gql from 'graphql-tag';

export const CLIENT_USER = gql`
  query UserByClientId($userId: ID) {
    userByClientId(userId: $userId) {
      name
      userId
      role
      company
      lastSeen
      settings {
        showFeedback
        avatar
        sound
        showChat
        theme
        noDisturb
        language
        theme
        location {
          country
          timezone
        }
      }
      email
      token
      userId
    }
  }
`;

export const FOCUS_MINUTES = gql`
  query FocusMinutes($userId: ID) {
    focusMinutes(userId: $userId) {
      week
      total
      day
      month
      allTimers
      completedTimers
      tasks
      rooms
    }
  }
`;

export const USER_TIMERS = gql`
  query UserTimers($userId: ID) {
    userTimers(userId: $userId) {
      timerId
      lastStartDate
      description
      settings {
        duration
      }
      isCompleted
    }
  }
`;

export const USER_ROOMS = gql`
  query GetUserRooms($userId: ID) {
    getUserRooms(userId: $userId) {
      roomId
      description
      timers
    }
  }
`;
export const USER_TIMERPODS = gql`
  query UserTimerPod($userId: ID!) {
    userTimerPod(userId: $userId) {
      timerPodId
      roomId
      description
      creationDate
      isCompleted
    }
  }
`;

export const USER_NOTIFICATIONS = gql`
  query UserNotifications($userId: ID) {
    userNotifications(userId: $userId) {
      label
      notificationId
      requestingUser
      type
      roomId
      date
    }
  }
`;

export const USER_LOGS = gql`
  query LatestEvents($userId: ID!) {
    latestEvents(userId: $userId) {
      description
      roomId
      date
      ownerId
      userId
      eventType
      room {
        roomId
        description
      }
      user {
        name
        settings {
          avatar
        }
      }
    }
  }
`;

export const USER_CONTRIBUTION = gql`
  query UserContribution($userId: ID, $from: Float, $to: Float) {
    userContribution(userId: $userId, from: $from, to: $to) {
      stats {
        total
        allTimers
        completedTimers
        tasks
      }
      contribution {
        date
        timers
      }
    }
  }
`;
