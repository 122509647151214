import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import {
  generateUserIdDispatcherAction,
  signOutUserDispatcherAction
} from '../../features/User/actions';
import { generateUserId } from '../../helpers/ids';
import AccountSettingsButton from './containers/AccountSettingsButton';
import TemplateActionsButton from './containers/TemplatesActionButton';
import NonAuthTemplateActionBtn from './containers/NonAuthTemplateActionBtn';
import { UPDATE_USER_STATE } from './graphql';
import { useTranslation } from 'react-i18next';
import { logAnalytics } from '../../utils';

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 15px;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.primary};
  text-decoration: none;
  &.is-active,
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
  }
`;

const NavActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  & > * {
    margin-right: 10px;
  }
  .login-signup-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  @media ${({ theme }) => theme.breakpoints.tabletPortraitDown} {
    .login-signup-link {
      display: none;
    }
    .button__create-timer {
      display: none;
    }
    .accountsettingsbutton {
      display: none;
    }
  }
`;

const NavActions = ({
  dispatch,
  history,
  client,
  isAdmin,
  isAuthenticated,
  userId
}: {
  dispatch: Function;
  history: any;
  client: any;
  isAdmin: boolean;
  isAuthenticated: boolean;
  userId: string;
}) => {
  const { t } = useTranslation(['common', 'navmenu']);
  const loginText = t('navmenu:login');
  const signupText = t('signup');

  useEffect(() => {
    const customInt = setInterval(async () => {
      if (isAuthenticated) {
        await updateUserStatus();
      }
    }, 10000);
    return () => {
      clearInterval(customInt);
    };
  }, [userId, isAuthenticated]);

  const updateUserStatus = async () => {
    await client.mutate({
      mutation: UPDATE_USER_STATE,
      variables: { userId: userId }
    });
  };
  const handleSignOut = async () => {
    history.push('/');
    const newUserId = generateUserId();
    dispatch(generateUserIdDispatcherAction(newUserId));
    sessionStorage.clear();
    dispatch(signOutUserDispatcherAction());
  };

  const showSideBar = () => {
    const btn: HTMLButtonElement = document.querySelector('.specialBtn') as any;
    if (btn) {
      btn.click();
    }
  };

  const goToStats = () => {
    history.push('/stats');
  };

  const goToAdmin = () => {
    history.push('/admin');
  };
  const goToCreateTimer = () => {
    console.log('logging event..');
    logAnalytics({ category: 'Timer', action: 'Create a new timer clicked' });
    history.push('/timer/create');
  };
  const goToRoom = () => {
    if (isAuthenticated) {
      history.push('/rooms/create');
    } else {
      history.push('/signup');
    }
  };
  const createTemplate = () => {
    if (isAuthenticated) {
      history.push('/templates/create');
    } else {
      history.push('/signup');
    }
  };
  const showAllTemplates = () => {
    if (isAuthenticated) {
      history.push('/templates');
    } else {
      history.push('/signup');
    }
  };
  const goToTemplate = (templateId: ID) => {
    history.push(`/timer/create/${templateId}`);
  };
  const goToVoting = () => {
    history.push('/voting-features');
  };

  return (
    <NavActionsContainer>
      {isAuthenticated ? (
        <TemplateActionsButton
          className="accountsettingsbutton"
          goToTemplate={goToTemplate}
          goToCreateTimer={goToCreateTimer}
          goToRoom={goToRoom}
          showAllTemplates={showAllTemplates}
          createTemplate={createTemplate}
          userId={userId}
        />
      ) : (
        <NonAuthTemplateActionBtn
          className="accountsettingsbutton"
          goToCreateTimer={goToCreateTimer}
          goToRoom={goToRoom}
          createTemplate={createTemplate}
        />
      )}
      {isAuthenticated ? (
        <AccountSettingsButton
          className="accountsettingsbutton"
          handleSignOut={handleSignOut}
          showSideBar={showSideBar}
          goToAdmin={goToAdmin}
          isAdmin={isAdmin}
          // handleSetting={handleSetting} // not needed right now, remove later.
          goToStats={goToStats}
          goToVoting={goToVoting}
          userId={userId}
        />
      ) : (
        <div className="login-signup-link">
          <StyledNavLink
            activeClassName="is-active"
            className="login-link"
            exact
            to={'/login'}
          >
            {loginText}
          </StyledNavLink>
          <StyledNavLink activeClassName="is-active" exact to={'/signup'}>
            {signupText}
          </StyledNavLink>
          {/* <NewTimerButtonContainer className="button__create-timer">
          <Button appearance="accent">{t('start-timer')}</Button>
        </NewTimerButtonContainer> */}
        </div>
      )}
    </NavActionsContainer>
  );
};

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth ? auth.isAuthenticated : false,
  userId: auth ? auth.userId : '',
  isAdmin: auth && auth.user && auth.user.role === 'ADMIN' ? true : false
});

export default withRouter(connect(mapStateToProps)(withApollo(NavActions)));
