import gql from 'graphql-tag';

export const NOTIFICATION_SUB = gql`
  subscription RoomNotificationSub($userId: ID) {
    roomNotificationSub(userId: $userId) {
      label
      date
      requestingUser
      roomId
      notificationId
      type
    }
  }
`;
