import React from 'react';
import { Link } from 'react-router-dom';

export const Anchor = ({
  text,
  to,
  color,
  children
}: {
  text?: string;
  to: string;
  color?: string;
  children?: any;
}) => (
  <Link
    style={{
      textDecoration: 'none',
      cursor: 'pointer',
      color: `${color || '#fff'}`
    }}
    to={to}
  >
    {text}
    {children}
  </Link>
);

export default Anchor;
