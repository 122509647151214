import gql from 'graphql-tag';

export const REGISTER = gql`
  mutation RegisterUser($userVm: UserRegInput) {
    registerUser(userVm: $userVm) {
      email
      name
      userId
      token
    }
  }
`;

export const CONNECT_SLACK = gql`
  mutation IntegrateSlack($userId: ID, $web_hook: String) {
    integrateSlack(userId: $userId, web_hook: $web_hook) {
      name
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation ResendEmail($mailVm: mailVmInput, $origin: String) {
    resendEmail(mailVm: $mailVm, origin: $origin) {
      name
    }
  }
`;

export const CREATE_PASSWORD = gql`
  mutation CreatePassowrd($passwordVm: CreatePassInput) {
    createPassowrd(passwordVm: $passwordVm) {
      email
      userId
      company
      role
      token
      name
      settings {
        showFeedback
        sound
        avatar
        theme
        noDisturb
        showChat
        language
        theme
        location {
          country
          timezone
        }
      }
    }
  }
`;

export const LOGIN = gql`
  mutation logingUser($loginVm: LoginInput, $timerPods: [TimerPodInput]) {
    logingUser(loginVm: $loginVm, timerPods: $timerPods) {
      name
      userId
      role
      company
      settings {
        language
        sound
        showFeedback
        theme
        showChat
        avatar
        noDisturb
      }
      email
      token
      userId
    }
  }
`;
export const GOOGLE_AUTH = gql`
  mutation GoogleAuth($userVm: GoogleUserInput, $timerPods: [TimerPodInput]) {
    googleAuth(userVm: $userVm, timerPods: $timerPods) {
      email
      token
      role
      userId
      company
      name
      settings {
        showChat
        noDisturb
        sound
        showFeedback
        avatar
        language
        theme
        location {
          timezone
          country
        }
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String, $origin: String) {
    forgotPassword(email: $email, origin: $origin) {
      email
      token
      role
      name
      userId
      settings {
        avatar
        sound
        noDisturb
      }
    }
  }
`;

export const EDIT_PASSWORD = gql`
  mutation EditPassword($password: String, $userId: String) {
    editPassword(password: $password, userId: $userId) {
      email
      name
      company
      userId
      role
      token
      settings {
        language
        sound
        theme
        avatar
        noDisturb
        showFeedback
        showChat
        location {
          timezone
          country
        }
      }
    }
  }
`;
