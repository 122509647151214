import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div<{ size?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: ${({ size }) => (size ? String(size) + 'px' : '35px')};
    height: ${({ size }) => (size ? String(size) + 'px' : '35px')};
  }
  &:hover {
    cursor: pointer;
  }
`;

export default ButtonContainer;
