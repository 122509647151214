import Link from '../../../../../../components/Common/Link';
import { Button } from '../../../../../../components/Elements';
import React from 'react';
import { MutationFn, Mutation } from 'react-apollo';
import { useEffect } from 'react';
import {
  DELETE_NOTIFICATION,
  GRANT_ACCESS
} from '../../../../../../components/Header/graphql';
import { ButtonsWrapper, Label, Box, Wrapper } from './Elements';

export default ({ notifications, refetch, subToMore }) => {
  const acceptHandler = async (
    mutation: MutationFn,
    deleteNotif: MutationFn,
    userId,
    roomId,
    id
  ) => {
    await mutation({ variables: { userId, roomId } });
    await deleteNotif({ variables: { id: id } });
    await discardNotification(deleteNotif, id, refetch);
    refetch();
  };

  const discardNotification = async (
    mutation: MutationFn,
    id: string,
    refetch: Function
  ) => {
    await mutation({ variables: { id: id } });
    refetch();
  };

  useEffect(() => {
    subToMore();
  }, [notifications]);
  return (
    <Mutation mutation={DELETE_NOTIFICATION}>
      {(deleteNotification: MutationFn) => {
        return (
          <Mutation mutation={GRANT_ACCESS}>
            {(grantAccess: MutationFn) => (
              <>
                {notifications && notifications.length === 0 && (
                  <p style={{ padding: '10px' }}>
                    you don't have any notifications
                  </p>
                )}
                {notifications.map((n, key) => (
                  <Wrapper key={key}>
                    <Box>
                      <Label>{n.label}</Label>
                      <ButtonsWrapper>
                        {n.type === 'ASK_FOR_PERMISSION' ? (
                          <Button
                            onClick={() =>
                              acceptHandler(
                                grantAccess,
                                deleteNotification,
                                n.requestingUser,
                                n.roomId,
                                n.notificationId
                              )
                            }
                          >
                            Accept
                          </Button>
                        ) : (
                          <Link to={`/room/${n.roomId}`}>
                            <Button
                              onClick={() =>
                                discardNotification(
                                  deleteNotification,
                                  n.notificationId,
                                  refetch
                                )
                              }
                            >
                              Go
                            </Button>
                          </Link>
                        )}
                        <Button
                          onClick={() =>
                            discardNotification(
                              deleteNotification,
                              n.notificationId,
                              refetch
                            )
                          }
                          marginLeft={10}
                        >
                          Discard
                        </Button>
                      </ButtonsWrapper>
                    </Box>
                  </Wrapper>
                ))}
              </>
            )}
          </Mutation>
        );
      }}
    </Mutation>
  );
};
