import { RightOutline } from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import { TextInput as EvergreenTextInput } from 'evergreen-ui';
import React from 'react';
import styled from 'styled-components';

const TextInput = styled(EvergreenTextInput)`
  height: ${({ height }: { height?: number }) =>
    height ? String(height) + 'px' : '40px'};
  width: ${({ width = 300 }: { width?: number | string }) =>
    typeof width === 'number' ? String(width) + 'px' : width};
  background: #f2f2f2;
  border: none;
  box-shadow: none;
  border-radius: 8px;
  padding: 5px 10px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  margin-top: ${({ marginTop = 0 }) => String(marginTop) + 'px'};
  margin-left: ${({ marginLeft = 0 }) => String(marginLeft) + 'px'};
  margin-bottom: ${({ marginBottom = 0 }) => String(marginBottom) + 'px'};
  margin-right: ${({ marginRight = 0 }) => String(marginRight) + 'px'};
  color: #000;
  outline: none;
`;

export default TextInput;

const TextInputStyler = styled.div<{
  size: string | number;
  background?: string;
}>`
  position: relative;
  width: ${({ size }) => {
    switch (size) {
      default:
        return '300px';
    }
  }};
  margin: 0;
  .baseInput {
    border-radius: 25px;
    background-color: ${({ theme, background }) =>
      theme.getTintsAndShades(background || 'gray')['100']};
    /* background-color: #E5F2F8; */
    border: none;
    width: 100%;
    height: ${({ size }) => {
      switch (size) {
        case 'big':
          return '52px';
        default:
          return '40px';
      }
    }};
    padding: 5px 20px;
    color: ${({ theme }) => theme.colors.dark};
    font-family: ${({ theme }) => theme.fonts.primary};
    outline: none;
    font-size: ${({ theme }) => theme.fontSize.base};
  }
`;

const SubmitButton = styled.div<{ size?: strOrNum; alt?: boolean }>`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => {
    switch (size) {
      default:
        return '30px';
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      default:
        return '30px';
    }
  }};
  background-color: ${({ theme, alt }) => {
    if (alt) return '#FFFFFF';
    else return theme.colors.primary;
  }};
  border-radius: 100%;
  margin: 0;
  padding: 5px;
  svg {

    fill: ${({ alt }) => {
      if (alt) return '#000000';
      else return '#FFFFFF';
    }}
  }
  &:hover {
    background-color: ${({ theme }) =>
      theme.getTintsAndShades('primary')['500']};
    cursor: pointer;
  }
}
`;

const CustomInput = ({
  name,
  value,
  onChange,
  onKeyDown,
  inputType,
  background,
  size = 'regular',
  withSubmit = false,
  onSubmit = () => {},
  alt = false,
  placeholder
}: {
  name?: string;
  background?: string;
  alt?: boolean;
  value: string;
  onChange: ChangeEvFunction;
  inputType?: string;
  size?: string;
  placeholder?: string;
  withSubmit?: boolean;
  onSubmit?: ClickEvFunction;
  onKeyDown?: OnKeyDownFunction;
}) => {
  return (
    <TextInputStyler background={background} size={size}>
      <input
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={inputType || 'text'}
        onKeyDown={onKeyDown}
        className={['baseInput', withSubmit ? 'withSubmit' : ''].join(' ')}
      />
      {withSubmit && (
        <SubmitButton size={size} onClick={onSubmit} alt={alt}>
          <AntdIcon type={RightOutline} />
        </SubmitButton>
      )}
    </TextInputStyler>
  );
};

export { CustomInput };
