import throttle from 'lodash/throttle';
import LogRocket from 'logrocket';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import settingsReducer from '../features/Setting/setting-action';
import tasksReducer from '../features/TaskList/actions';
import { initialState as tasksInitialState } from '../features/TaskList/actions';
import userReducer from '../features/User/actions';
import { initialState as authInitialState } from '../features/User/actions';
import { loadState, saveState } from './localStorage';

// initial state to compare with incoming state
const appInitialState: object = {
  auth: authInitialState,
  tasks: tasksInitialState,
};

const logger = createLogger({
  diff: true
});

const compose =
  process.env.NODE_ENV !== 'production'
    ? composeWithDevTools(applyMiddleware(thunk))
    : composeWithDevTools(applyMiddleware(thunk, LogRocket.reduxMiddleware()));

const persistedState = loadState(appInitialState);

const rootReducer = combineReducers({
  auth: userReducer,
  settings: settingsReducer,
  tasks: tasksReducer
});

const store = createStore(rootReducer, persistedState || undefined, compose);
store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

export default store;
