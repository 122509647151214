import ReactGA from 'react-ga';
export const logAnalytics = ({
  category = '',
  action = '',
  label = '',
  value = 0
}) => {
  if (true) {
    const analyticData = { category, action, value, label };
    ReactGA.event(analyticData);
  }
};

// logAnalytics({category: ‘Transaction’, action: ‘Deposit Button Clicked’});
