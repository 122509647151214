import React from 'react';
import { Query, QueryResult } from 'react-apollo';
import PopupMenu from '../../Elements/PopupMenu';
import { adopt } from 'react-adopt';
import { PlusCircleFill } from '@ant-design/icons';
import styled from 'styled-components';
import { AntIcon } from '../../Elements';
import MenuItem from '../../Elements/PopupMenu/MenuItem';
import {
  USER_TEMPLATES,
  IUserTemplateQuery,
  UserTemplatesVar
} from '../graphql';
import { useTranslation } from 'react-i18next';
import defaultTheme from '../../../styles/themes';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.tabletPortraitDown} {
    display: none;
  }
`;

type QueryData = { userTemplates: ITemplate[] };

const renderTemplates = (data: QueryData, goToTemplate: Function) => {
  if (data && data.userTemplates && data.userTemplates.length) {
    return data.userTemplates.map((tem, key) => (
      <MenuItem key={key} onClick={() => goToTemplate(tem.templateId)}>
        {tem.name}
      </MenuItem>
    ));
  }
  return null;
};

const TemplateActions = ({
  goToRoom,
  className,
  userId,
  createTemplate,
  goToTemplate,
  showAllTemplates,
  goToCreateTimer
}: {
  goToRoom: Function;
  showAllTemplates: Function;
  className: string;
  userId: string;
  goToTemplate: Function;
  createTemplate: Function;
  goToCreateTimer: Function;
}) => {
  const Composed = adopt({
    userTemplateQuery: ({ render }) => (
      <Query<IUserTemplateQuery, UserTemplatesVar>
        query={USER_TEMPLATES}
        variables={{ userId, limit: 4 }}
      >
        {render}
      </Query>
    )
  });
  const { t } = useTranslation('navmenu');
  return (
    <Composed>
      {({ userTemplateQuery }: { userTemplateQuery: QueryResult }) => {
        const { data, loading, error } = userTemplateQuery;
        if (error || loading) {
          return null;
        }
        return (
          <Wrapper className={'template-action-btns'}>
            <PopupMenu
              showAvatar={false}
              showPlus={true}
              color={defaultTheme.colors.accent}
              icon={PlusCircleFill}
              userId={userId}
              top={60}
            >
              <MenuItem onClick={() => goToCreateTimer()} key="create-timer">
                {t('create-timer')}
              </MenuItem>
              <MenuItem onClick={() => goToRoom()} key="create-room">
                {t('create-room')}
              </MenuItem>
              <MenuItem
                className="create-template-btn"
                onClick={() => createTemplate()}
                key="create-template"
              >
                <AntIcon
                  type={PlusCircleFill}
                  marginRight="5px"
                  color={'#222'}
                />
                {t('create-template')}
              </MenuItem>

              {renderTemplates(data, goToTemplate)}

              <MenuItem
                className="show-all-templates"
                onClick={() => showAllTemplates()}
                key="show-all-templates"
              >
                {t('show-all-templates')}
              </MenuItem>
            </PopupMenu>
          </Wrapper>
        );
      }}
    </Composed>
  );
};

export default TemplateActions;
