import { logError } from '../utils/log';
import { autoMergeLevel2 } from '../utils/stateMerger';

// Just a way to manually force a restart on the localstorage
const CURRENT_CACHE_V = 1.8;

// import initialstate from the keys being saved. use autoMergeLevel2 to merge the incoming (localStroage state) with the initialState
// initialStates to import auth, tasks, timers

export const loadState = defaultState => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const incomingState = JSON.parse(serializedState);
    // we check the version of the state on localstorage. If different from ours, delete.
    if (incomingState.cacheV !== CURRENT_CACHE_V) {
      return { auth: { userId: incomingState.auth.userId } };
    }
    const mergedState = autoMergeLevel2(defaultState, incomingState);
    // we remove the cacheV key before passing this to redux to avoid a redux warning.
    delete mergedState['cacheV'];
    return mergedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  const { auth, timers, tasks } = state;
  try {
    const serializedState = JSON.stringify({
      // added the cacheV key as a way to force force a cache clean-up when needed.
      cacheV: CURRENT_CACHE_V,
      auth,
      tasks,
      timers
    });
    localStorage.setItem('state', serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveToLocalStoarge = (key: string, value: any) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    logError(err, 'LOCAL_STOARGE_ERROR');
    console.log('your browser does not support local storage');
    return null;
  }
};
