import { Spinner as LibSpinner } from 'evergreen-ui';
import { Wrapper } from './Wrapper';
import React from 'react';
export const Spinner = () => (
  <Wrapper paddingTop="100px" flex center>
    <LibSpinner />
  </Wrapper>
);

export default Spinner;
