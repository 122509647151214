import React from 'react';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SideBar from '../../features/User/containers/WorkSpace';
import BrandLogo from './BrandLogo';
import HeaderBar from './HeaderBar';
import MainNavigation from './MainNavigation';
import MobilePopupMenu from './MobilePopupMenu';
import NavActions from './NavActions';
import NavMenuLink from './NavMenuLink';

const BrandContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .LogoLink {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;

const Header = () => {
  return (
    <HeaderBar className="headerbar">
      <BrandContainer className="BrandContainer">
        <NavMenuLink
          className="LogoLink"
          activeClassName="is-active"
          exact
          to="/"
        >
          <BrandLogo />
        </NavMenuLink>
        <MainNavigation />
      </BrandContainer>
      <NavActions />
      <MobilePopupMenu />

      {/* Sidebar, Workspace */}
      <SideBar />
    </HeaderBar>
  );
};

const mapStateToProps = ({ auth }) => ({
  userId: auth.userId,
  isAuthenticated: auth.isAuthenticated
});

export default connect(mapStateToProps)(withApollo(Header));
