import React from 'react';
import PageWrapper from '../PageWrapper';
import { FadingDots } from '../Animations/LoadingDots';
import styled from 'styled-components';

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const LoadingDots = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 4px;
  background: ${({ theme }) => theme.colors.primary};
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  opacity: 0;
  -webkit-box-shadow: 0 0 2px black;
  -moz-box-shadow: 0 0 2px black;
  -ms-box-shadow: 0 0 2px black;
  -o-box-shadow: 0 0 2px black;
  box-shadow: 0 0 2px black;
  -webkit-animation: ${FadingDots} 1s infinite;
  -moz-animation: ${FadingDots} 1s infinite;
  animation: ${FadingDots} 1s infinite;

  &:nth-child(1) {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    animation-delay: 0s;
  }

  &:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  &:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  &:nth-child(4) {
    -webkit-animation-delay: 0.3s;
    -moz-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
`;

export const Loading = ({ story }: { story?: boolean }) => (
  <PageWrapper story={story}>
    <Centered>
      <LoadingDots />
      <LoadingDots />
      <LoadingDots />
      <LoadingDots />
    </Centered>
  </PageWrapper>
);
