import { toast } from 'react-toastify';

type IToastifyType = 'INFO' | 'DONE' | 'SUCCESS' | 'ERROR' | 'WARN';
const config = { autoClose: 3000, hideProgressBar: true };

export const toastify = (type: IToastifyType, msg: string) => {
  switch (type) {
    case 'INFO':
      toast.info(msg, config);
      break;
    case 'DONE':
      toast.info(msg, config);
      break;
    case 'ERROR':
      toast.error(msg, config);
      break;
    case 'SUCCESS':
      toast.success(msg, config);
      break;
    case 'WARN':
      toast.warn(msg, config);
      break;
  }
};
