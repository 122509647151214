import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const NavMenuLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  margin: 0 10px;
  &:hover {
    cursor: pointer;
    font-weight: 500;
  }
  &.is-active {
    font-weight: 600;
  }
`;

export default NavMenuLink;
