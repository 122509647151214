/// <reference path="../__types__/types.d.ts" />
import React from 'react';
import { Link } from 'react-router-dom';

export const Anchor = ({ text, to, color, children }: LinkProps) => (
  <Link style={{ textDecoration: 'none', color: `${color || '#fff'}` }} to={to}>
    {text}
    {children}
  </Link>
);

export default Anchor;
