import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = ({ userLanguage }) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(userLanguage);
  }, [userLanguage]);

  return null;
};

const mapStateToProps = state => {
  return {
    userLanguage:
      state.auth &&
      state.auth.user &&
      state.auth.user.settings &&
      state.auth.user.settings.language
        ? state.auth.user.settings.language
        : 'en'
  };
};

export default connect(mapStateToProps)(LanguageSwitcher);
