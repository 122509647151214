import { addMinutes, differenceInSeconds, subSeconds } from 'date-fns';

import { generateId } from '../../../utils/generalUtils';

export const createTimer = ({
  timerId = generateId(),
  duration = 25,
  userId = '',
  isRunning = false,
  description = '',
  from = new Date().toISOString(),
  timezone = '',
  taskId = '',
  createdByUser = false,
  timerType = 'TIMER',
  isBreak = false
} = {}): ITimer => {
  const timer = {
    timerId,
    userId,
    taskId,
    description,
    from,
    to:
      timerType === 'DATE_COUNTDOWN' ? new Date() : addMinutes(from, duration),
    isCompleted: false,
    isRunning: isRunning,
    elapsed: 0,
    creationDate: new Date().toISOString(),
    lastStartDate: new Date().toISOString(),
    completionDate: '',
    createdByUser,
    isBreak,
    settings: {
      duration,
      timezone,
      timerType
      // musicIsPlaying: false,
    }
  };
  return timer as any;
};

export const FormatTime = (time: number): string => {
  if (time <= 0) {
    return String('00');
  }

  if (time < 10) {
    return String('0' + time);
  }
  return String(time);
};

export const calculatePercentage = ({
  isCompleted,
  secondsToCountdown,
  duration
}: {
  isCompleted: boolean;
  secondsToCountdown: number;
  duration: number;
}): number => {
  if (secondsToCountdown <= 0 || isCompleted) {
    return 100;
  } else {
    return Math.floor(
      ((duration * 60 - secondsToCountdown) / (duration * 60)) * 100
    );
  }
};

export const sanitizeTimer = (timer: any, user: any) => {
  // cant make it shorter
  // see https://stackoverflow.com/a/35532539/2577494
  const {
    description,
    timerId,
    from,
    to,
    lastStartDate,
    elapsed,
    isRunning,
    isCompleted,
    settings
  } = timer;

  const newTimer = {
    description,
    timerId,
    from,
    to,
    lastStartDate,
    elapsed,
    isRunning,
    isCompleted,
    settings,
    userId: user.userId
  };
  return newTimer;
};

export const isOwner = (timerUser: any, currentUserId: string): boolean => {
  return timerUser.userId === currentUserId;
};
export const calculateDifference = ({
  elapsed,
  lastStartDate
}: {
  elapsed: number;
  lastStartDate: string | Date;
}): number => {
  return elapsed + differenceInSeconds(new Date().toISOString(), lastStartDate);
};

export const produceFormattedTime = (secondsToCountdown: number): string => {
  const FormattedMinutes = FormatTime(Math.floor(secondsToCountdown / 60));
  const FormattedSeconds = FormatTime(secondsToCountdown % 60);
  return FormattedMinutes + ':' + FormattedSeconds;
};

interface ICreateTimerPodObject {
  timerPodId?: ID;
  userId?: ID;
  roomId?: ID;
  taskId?: ID;
  description?: string;
  templateId?: ID;
  timersId?: ID[];
  autoStart?: boolean;
}

export const createTimerPodObject = ({
  timerPodId = generateId(),
  userId = '',
  templateId = '',
  taskId = '',
  description = '',
  roomId = '',
  timersId = [],
  autoStart = false
}: ICreateTimerPodObject) => ({
  timerPodId,
  userId,
  taskId,
  description,
  creationDate: new Date(),
  totalCompleted: 0,
  roomId,
  templateId,
  timersId: [...timersId],
  position: {
    x: 400,
    y: 200,
    center: true
  },
  settings: {
    duration: 25,
    backgroundImage: '',
    unsplashTerm: '',
    loop: false,
    songSelected: '',
    musicIsPlaying: false,
    autoStart,
    timerType: 'TIMER',
    breakInterval: 0,
    breakDuration: 5,
    breakCounter: 0
  }
});

export const getTimeZone = (user: any) => {
  if (
    user &&
    user.settings &&
    user.settings.location &&
    user.settings.location.timezone
  ) {
    return user.settings.location.timezone;
  } else {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return browserTimeZone.trim().replace(' ', '_');
  }
};

export const getUpdatedTimer = ({ isRunning, timer, timerPod }) => {
  const newFrom = new Date().toISOString();
  const { elapsed, lastStartDate } = timer;
  const { duration } = timer.settings;
  const newTo = subSeconds(addMinutes(newFrom, duration), elapsed);
  const {
    settings: { timerType }
  } = timerPod;

  if (timerType === 'DATE_COUNTDOWN') {
    let updatedTimer = {
      ...timer,
      isRunning
    };
    return updatedTimer;
  }

  let updatedTimer = {
    ...timer,
    isRunning
  };
  if (isRunning) {
    updatedTimer = {
      ...updatedTimer,
      isRunning,
      from: newFrom,
      to: newTo,
      lastStartDate: new Date().toISOString()
    };
  } else {
    let newElapsed = calculateDifference({ elapsed, lastStartDate });
    updatedTimer = {
      ...updatedTimer,
      isRunning,
      elapsed: newElapsed
    };
  }
  return updatedTimer;
};

export const generateSongListOptions = (
  userSongList: any[] = [],
  defaultMusicList
) => {
  const defaultSongOptions = defaultMusicList.map(song => ({
    label: song.name,
    value: `https://www.youtube.com/watch?v=${song.vidId}`
  }));
  const userSongOptions = userSongList.map(
    (val: { label: string; youtubeId: string }) => ({
      label: val.label,
      value: val.youtubeId
    })
  );
  const combinedSongOptions = [...userSongOptions, ...defaultSongOptions];
  return combinedSongOptions;
};

export const getTimerDuration = timerPodSettings => {
  if (timerPodSettings && timerPodSettings.duration) {
    if (
      timerPodSettings &&
      (timerPodSettings.breakCounter !== null ||
        timerPodSettings.breakCounter !== undefined) &&
      timerPodSettings.breakInterval &&
      timerPodSettings.breakInterval > 0
    ) {
      // add 1 to the count because this counter hasn't been updated yet, it will be on the server
      const count = timerPodSettings.breakCounter + 1;
      if (count % timerPodSettings.breakInterval === 0) {
        // return a timer with the break duration if it is time.
        return timerPodSettings.breakDuration || 5;
      }
    }
    // return the current timerpod duration
    return timerPodSettings.duration;
  }
  // default value
  return 25;
};

export const isBreakTimer = timerPodSettings => {
  if (
    timerPodSettings &&
    timerPodSettings.breakInterval &&
    timerPodSettings.breakInterval > 0 &&
    (timerPodSettings.breakCounter !== null ||
      timerPodSettings.breakCounter !== undefined)
  ) {
    const count = timerPodSettings.breakCounter + 1;
    return count % timerPodSettings.breakInterval === 0;
  }

  return false;
};
