import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

export const Box = styled.div`
  border: 1px solid #ccc;
  padding: 10px 20px;
  font-size: 1.2rem;
  display: grid;
  grid-gap: 5px;
`;

export const Label = styled.p`
  font-weight: 300;
  font-size: 1.2rem;
  color: #222;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  /* grid-template-columns: minmax(1fr, 150px) minmax(1fr, 150px); */
  /* grid-template-columns: 1fr 1fr */
  flex-direction: row;
  grid-gap: 5px;
`;
