import axios from 'axios';
import geolocator from 'geolocator';
import { IP_API } from '../api/env';

var options = {
  enableHighAccuracy: true,
  timeout: 50001,
  maximumWait: 10000, // max wait time for desired accuracy
  maximumAge: 0, // disable cache
  desiredAccuracy: 30, // meters
  fallbackToIP: true // fallback to IP if Geolocation fails or rejected
};
export const country = () => {
  geolocator.locate(options, function(err, location) {
    if (err) return console.log(err);
  });
};

export const getUserTimeZone = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tz;
};

export const getCountry = async () => {
  try {
    const url = 'https://ipapi.co/json';
    const urll =
      'https://api.ipdata.co/?api-key=73a49eac1a36a47e0c36490dbb414f1b3d18d0fc90a60e9dd21ff8cb';
    const result = await axios.get(url);
    console.log('result', result);
    return result.data;
  } catch (err) {
    console.log('err', err);
    return { country_name: '', timezone: '' };
  }
};
