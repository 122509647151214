import { generatePalette } from '../utils/colorUtils';

const colors = {
  primary: '#0D5fe4',
  accent: '#05d8a3',
  secondary: '#27d7fb',
  light: '#e5f2f8',
  gray: '#929292',
  danger: '#d0021b',
  alert: '#e9ad00',
  info: '#708ccb',
  success: '#8ac34a',
  black: '#000000',
  white: '#F9F9F9',
  dark: '#21252c',
  lightDark: '#343c48'
};

export default {
  themeName: 'LIGHT',
  colors: {
    primary: colors.primary,
    accent: colors.accent,
    black: colors.black,
    lightBlack: colors.black,
    light: colors.light,
    gray: colors.gray,
    danger: colors.danger,
    success: colors.success,
    alert: colors.alert,
    info: colors.info,
    white: colors.white,
    lightDark: colors.dark
  },
  getTintsAndShades: (color: string) => {
    return generatePalette(colors[color]);
  },
  fonts: {
    primary: "'Work Sans', sans-serif"
  },
  fontSize: {
    xs: '10px',
    sm: '12px',
    base: '15px',
    lg: '20px',
    xl: '30px'
  },
  fontWeight: {
    bold: '700',
    regular: '400',
    light: '300',
    semiBold: '600',
    extraBold: '900'
  },
  shadows: {
    main: '0px 6px 20px rgba(0, 0, 0, 0.06)',
    secondary: '0 27px 27px 0 rgba(0,0,0,0.06)'
  },
  // breakpoints taken from this article
  // https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
  breakpoints: {
    bigDesktop: '(min-width: 1800px)',
    desktop: '(min-width: 1200px) and (max-width: 1799px)',
    desktopUp: '(min-width: 1200px)',
    tabletLandscapeDown: '(max-width: 1199px)',
    tabletPortraitUp: '(min-width: 900px)',
    tabletPortraitDown: '(max-width: 899px)',
    tabletLandscape: '(min-width: 900px) and (max-width: 1199px)',
    tabletPortrait: '(min-width: 450px) and (max-width: 899px)',
    mobileUp: '(min-width: 450px)',
    mobileDown: '(max-width: 485px)',
    mobile: '(max-width: 450px)'
  }
};

export const darkTheme = {
  themeName: 'DARK',
  colors: {
    primary: colors.primary,
    accent: colors.accent,
    black: colors.white,
    light: colors.light,
    gray: colors.gray,
    danger: colors.danger,
    success: colors.success,
    alert: colors.alert,
    info: colors.info,
    white: colors.dark,
    lightDark: colors.lightDark
  },
  getTintsAndShades: (color: string) => {
    return generatePalette(colors[color]);
  },
  fonts: {
    primary: "'Work Sans', sans-serif"
  },
  fontSize: {
    xs: '10px',
    sm: '12px',
    base: '15px',
    lg: '20px',
    xl: '30px'
  },
  fontWeight: {
    bold: '700',
    regular: '400',
    light: '300',
    semiBold: '600',
    extraBold: '900'
  },
  shadows: {
    main: '0px 6px 20px rgba(0, 0, 0, 0.06)',
    secondary: '0 27px 27px 0 rgba(0,0,0,0.06)'
  },
  // breakpoints taken from this article
  // https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
  breakpoints: {
    bigDesktop: '(min-width: 1800px)',
    desktop: '(min-width: 1200px) and (max-width: 1799px)',
    desktopUp: '(min-width: 1200px)',
    tabletLandscapeDown: '(max-width: 1199px)',
    tabletPortraitUp: '(min-width: 900px)',
    tabletPortraitDown: '(max-width: 899px)',
    tabletLandscape: '(min-width: 900px) and (max-width: 1199px)',
    tabletPortrait: '(min-width: 450px) and (max-width: 899px)',
    mobileUp: '(min-width: 450px)',
    mobile: '(max-width: 450px)'
  }
};
