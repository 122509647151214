import styled from 'styled-components';

export const Text = styled.p<{
  weight?: number;
  size?: string;
  marginBottom?: number;
  color?: string;
  marginTop?: number;
  display?: string;
  marginLeft?: number;
  link?: boolean;
  marginRight?: number;
}>`
  outline: none;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ color }) => color};
  display: ${({ display }) => display};
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => `${size} !important`};
  margin-top: ${({ marginTop = 0 }) => String(marginTop) + 'px'};
  margin-left: ${({ marginLeft = 0 }) => String(marginLeft) + 'px'};
  margin-bottom: ${({ marginBottom = 0 }) => String(marginBottom) + 'px'};
  margin-right: ${({ marginRight = 0 }) => String(marginRight) + 'px'};
  :hover {
    text-decoration: ${({ link }) => (link ? 'underline' : 'inheret')};
  }
`;
