import gql from 'graphql-tag';

// QUERIES

const GET_TIMER_SETTINGS = gql`
  query GetTimer($timerId: ID!) {
    getTimer(timerId: $timerId) {
      timerId
      userId
      settings {
        timezone
        duration
      }
      isCompleted
    }
  }
`;

const GET_TIMER_DATA = gql`
  query GetTimer($timerId: ID!) {
    getTimer(timerId: $timerId) {
      timerId
      userId
      from
      to
      isRunning
      elapsed
      lastStartDate
      description
      isCompleted
      isBreak
      settings {
        duration
        backgroundImage
        songSelected
        timezone
      }
      user {
        userId
        name
        email
        settings {
          avatar
          location {
            country
          }
        }
      }
      task {
        taskId
        description
      }
    }
  }
`;

const GET_TIMERPOD_PAGE_DATA = gql`
  query GetTimerPorPageData($timerPodId: ID!) {
    timerPod(timerPodId: $timerPodId) {
      timerPodId
      description
      totalCost
      timersId
      followers
      totalCompleted
      userId
      position {
        x
        y
        center
      }
      settings {
        backgroundImage
        musicIsPlaying
        songSelected
        duration
        loop
        autoStart
        charge
        unsplashTerm
        timerType
        breakInterval
        breakDuration
        breakCounter
      }
      user {
        userId
        name
        email
        settings {
          youtubeList {
            label
            youtubeId
          }
          location {
            country
            timezone
          }
          avatar
        }
      }
      taskId
      task {
        taskId
        description
        isCompleted
        timers {
          timerId
          isBreak
          isCompleted
        }
      }
      timers {
        timerId
        from
        userId
        to
        isCompleted
        isBreak
        isRunning
        elapsed
        lastStartDate
        completionDate
        settings {
          timezone
          duration
        }
      }
    }
  }
`;

const GET_TIMERPOD_DATA = gql`
  query GetTimerPodData($timerPodId: ID!) {
    timerPod(timerPodId: $timerPodId) {
      timerPodId
      totalCost
      followers
      description
      timersId
      totalCompleted
      userId
      position {
        x
        y
        center
      }
      settings {
        backgroundImage
        musicIsPlaying
        songSelected
        duration
        loop
        charge
        autoStart
        unsplashTerm
        timerType
        breakInterval
        breakDuration
        breakCounter
      }
      user {
        userId
        name
        email
        settings {
          location {
            country
            timezone
          }
          avatar
          youtubeList {
            label
            youtubeId
          }
        }
      }
      taskId
      task {
        taskId
        description
        isCompleted
        timers {
          timerId
          isBreak
          isCompleted
        }
      }
      description
      timers {
        timerId
        from
        userId
        to
        isCompleted
        completionDate
        isRunning
        isBreak
        elapsed
        lastStartDate
        settings {
          timezone
          duration
        }
      }
    }
  }
`;

// SUBSCRIPTIONS

const SUBSCRIBE_TO_TIMERPOD_DATA = gql`
  subscription TimerPodSub($timerPodId: ID) {
    timerPodSub(timerPodId: $timerPodId) {
      timerPodId
      followers
      description
      timersId
      totalCost
      userId
      totalCompleted
      position {
        x
        y
        center
      }
      user {
        userId
        name
        email
        settings {
          youtubeList {
            label
            youtubeId
          }
          location {
            country
            timezone
          }
          avatar
        }
      }
      taskId
      task {
        taskId
        description
        isCompleted
        timers {
          timerId
          isBreak
          isCompleted
        }
      }
      description
      timers {
        timerId
        from
        userId
        to
        isCompleted
        isBreak
        completionDate
        isRunning
        elapsed
        lastStartDate
        settings {
          timezone
          duration
        }
      }
      settings {
        backgroundImage
        musicIsPlaying
        songSelected
        duration
        loop
        autoStart
        charge
        unsplashTerm
        timerType
        breakInterval
        breakDuration
        breakCounter
      }
    }
  }
`;

// MUTATIONS

const UPDATE_TIMERPOD = gql`
  mutation TimerPodDynamicCreate($timerPod: TimerPodInput!) {
    timerPodDynamicCreate(timerPod: $timerPod) {
      timerPodId
    }
  }
`;

export const ATTEND_TIMERPOD = gql`
  mutation FollowTimerPod($timerPodId: ID, $userId: ID, $unfollow: Boolean) {
    followTimerPod(
      timerPodId: $timerPodId
      userId: $userId
      unfollow: $unfollow
    ) {
      followers
    }
  }
`;

export const SHOULD_CALL = gql`
  query ShouldCall($timerId: ID) {
    shouldCall(timerId: $timerId) {
      call
    }
  }
`;

const COMPLETE_AND_ADD_TIMER = gql`
  mutation CompletedTimerAndCreate(
    $completedTimerId: ID
    $timerPodId: ID
    $timerVm: TimerInput
  ) {
    completedTimerAndCreate(
      completedTimerId: $completedTimerId
      timerPodId: $timerPodId
      timerVm: $timerVm
    ) {
      timerPodId
      timersId
      followers
      timers {
        timerId
        from
        userId
        to
        isBreak
        isCompleted
        completionDate
        isRunning
        elapsed
        lastStartDate
        isBreak
        settings {
          timezone
          duration
        }
      }
    }
  }
`;

const COMPLETE_TIMER = gql`
  mutation MarkAsComplete($timerId: ID, $isComplete: Boolean, $timerPodId: ID) {
    markAsComplete(
      timerId: $timerId
      isComplete: $isComplete
      timerPodId: $timerPodId
    ) {
      timerId
      isCompleted
    }
  }
`;

const UPDATE_TIMER = gql`
  mutation CreateOrUpdate($timer: TimerInput!, $templateId: ID) {
    createOrUpdate(timerVm: $timer, templateId: $templateId) {
      timerId
      userId
      from
      to
      isRunning
      elapsed
      isBreak
      lastStartDate
      description
      isCompleted
      isBreak
      settings {
        duration
        backgroundImage
        songSelected
        timezone
      }
    }
  }
`;

const DELETE_TIMERPOD = gql`
  mutation DeleteTimerPod($timerPodId: ID!) {
    deleteTimerPod(timerPodId: $timerPodId) {
      timerPodId
    }
  }
`;

// Important: Updates on timer will only trigger a TimerPod sub refetch if done with this mutation.
const UPDATE_TIMER_IN_TIMERPOD = gql`
  mutation UpdateTimerInTimerPod($timerVm: TimerInput, $timerPodId: ID) {
    updateTimerInTimerPod(timerVm: $timerVm, timerPodId: $timerPodId) {
      timerId
      userId
      from
      to
      isRunning
      elapsed
      lastStartDate
      isCompleted
      isBreak
      completionDate
      settings {
        duration
        timezone
      }
    }
  }
`;

export {
  GET_TIMER_SETTINGS,
  UPDATE_TIMER,
  GET_TIMER_DATA,
  GET_TIMERPOD_PAGE_DATA,
  GET_TIMERPOD_DATA,
  UPDATE_TIMER_IN_TIMERPOD,
  UPDATE_TIMERPOD,
  SUBSCRIBE_TO_TIMERPOD_DATA,
  COMPLETE_TIMER,
  DELETE_TIMERPOD,
  COMPLETE_AND_ADD_TIMER
};
