import { isDev } from './index';

export default () =>
  isDev ? 'http://localhost:5000' : 'https://tmrapi.pragmatimer.com/graphql';

export const appLink = () =>
  isDev ? 'http://localhost:3000' : 'https://stage.tmr.to';

export const SLACK_CODE_EXCHANGE = 'https://slack.com/api/oauth.access';

export const socketLink = () =>
  isDev ? 'ws://localhost:5000/graphql' : 'wss://tmrapi.pragmatimer.com/socket';

export const SERVER_API_KEY = 'SUBTOPEWDIEPIE9YEARSOLDDUDEE';

export const SLACK_CLIENT_ID = '677668085329.672605711010';

export const SLACK_CLIENT_SECRET = 'f42b74c5f03c445617227a680b6ec4c1';
