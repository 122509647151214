import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faIgloo } from '@fortawesome/free-solid-svg-icons';
import { HttpLink } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, concat } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import React, { useEffect, useState } from 'react';
import { ApolloProvider } from 'react-apollo';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { BreakpointProvider } from 'react-socks';
import { ToastContainer } from 'react-toastify';
import {
  HELP_CHURCH_1,
  HELPCHURCH_2,
  HELPCHURCH_UNLOAD,
  USER_BACK_SCRIPT
} from './api/env';
import Notification from './components/Common/Notification';
import ContextProvider from './components/ContextProvider';
import Fullscreen from './components/Fullscreen';
import Header from './components/Header';
import LogRocketRegisterer from './components/LogRocketRegisterer';
import ErrorBoundary from './features/ErrorBoundaries/ErrorBoundary';
import { getCurrentThemeDispatcher } from './features/Setting/setting-action';
import { generateUserIdDispatcherAction } from './features/User/actions';
import SaveToken from './features/User/containers/SaveToken';
import UserIdManager from './features/User/containers/UserIdManager';
import history from './helpers/history';
import { generateUserId } from './helpers/ids';
import initDebuggers from './helpers/initDebuggers';
import routes from './routes';
import GlobalStyles from './styles/GlobalStyles';
import loadGoogleFonts from './styles/loadGoogleFonts';
import { MAIN_HOST, SOCKET_LINK, SERVER_API_KEY } from './utils';
import { subscribeUser } from './send-sub';
import './translations/i18n';
import LanguageSwitcher from './components/LanguageSwitcher';
import OwnThemeProvider from './components/OwnThemeProvider';

const wsLink = new WebSocketLink({
  uri: SOCKET_LINK,
  options: {
    reconnect: true
  }
});

const headers = {
  'x-token': sessionStorage.getItem('x-token'),
  'api-key': SERVER_API_KEY
};

const httpLink = new HttpLink({
  uri: MAIN_HOST,
  headers: headers
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'x-token': sessionStorage.getItem('x-token') || ''
    }
  });
  return forward ? forward(operation) : null;
});

const retry = new RetryLink({
  delay: { jitter: false },
  attempts: { max: 10000 }
});
const link = retry.split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  concat(authMiddleware, httpLink)
);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({ addTypename: false })
});

library.add(faIgloo);
loadGoogleFonts();
// init logrocket and sentry
initDebuggers();

const App = ({ dispatch, userId, isAuthenticated, showChat, showFeedback }) => {
  const createUserId = async () => {
    const userId = generateUserId();
    await dispatch(generateUserIdDispatcherAction(userId));
  };

  const userBack = (showFeedback: boolean, showChat: boolean) => {
    try {
      if (!showChat && window['HelpCrunch']) {
        window['HelpCrunch']('hideChatWidget');
      } else if (showChat && window['HelpCrunch']) {
        window['HelpCrunch']('showChatWidget');
      }
      const userBackTrigger: HTMLButtonElement = document.querySelector(
        showFeedback ? '.open-user-back' : '.close-user-back'
      ) as any;
      if (userBackTrigger && window['Userback']) {
        userBackTrigger.click();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    userBack(showFeedback, showChat);
  }, [showFeedback, showChat]);

  useEffect(() => {
    setTimeout(() => {
      subscribeUser();
    }, 5000);
  }, [userId]);

  useEffect(() => {
    userBack(showFeedback, showChat);
    dispatch(getCurrentThemeDispatcher());
    if (!userId) {
      createUserId();
    }
    subscribeUser();
  }, []);

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <script id="userBackElement">
          {showFeedback ? USER_BACK_SCRIPT : ''}
        </script>
        <script>{HELP_CHURCH_1}</script>
        <script>{showChat ? HELPCHURCH_2 : HELPCHURCH_UNLOAD}</script>
      </Helmet>
      <OwnThemeProvider>
        <BreakpointProvider>
          <Router history={history}>
            <LastLocationProvider>
              <ApolloProvider client={client}>
                <SaveToken isAuthenticated={isAuthenticated} userId={userId} />
                <LogRocketRegisterer userId={userId} />
                <LanguageSwitcher />
                <div className="App">
                  <ContextProvider>
                    <Fullscreen>
                      <Header />
                      <UserIdManager />
                      <a
                        style={{ visibility: 'hidden', position: 'absolute' }}
                        onClick={() =>
                          window['Userback'] && window['Userback'].hide()
                        }
                        className="close-user-back"
                      >
                        hide
                      </a>
                      <a
                        style={{ visibility: 'hidden', position: 'absolute' }}
                        className="open-user-back"
                        onClick={() =>
                          window['Userback'] && window['Userback'].show()
                        }
                      >
                        show
                      </a>
                      <ErrorBoundary>
                        <Switch>{routes}</Switch>
                      </ErrorBoundary>
                    </Fullscreen>
                  </ContextProvider>
                </div>
                <Notification userId={userId} />
              </ApolloProvider>
            </LastLocationProvider>
          </Router>
        </BreakpointProvider>
      </OwnThemeProvider>
      <ToastContainer position="bottom-left" />
    </>
  );
};

const mapStateToProps = state => ({
  userId: state.auth.userId,
  showFeedback:
    state.auth.user && state.auth.user.settings
      ? state.auth.user.settings.showFeedback
      : false,
  showChat:
    state.auth.user && state.auth.user.settings
      ? state.auth.user.settings.showChat
      : false,
  isAuthenticated: state.auth.isAuthenticated,
  currentTheme: state.settings.theme
});

export default connect(mapStateToProps)(App);
