import { RadioGroup } from 'evergreen-ui';
import styled from 'styled-components';

const StyledRadioGroup = styled(RadioGroup)`
  label > span {
    color: ${({ theme }) =>
      theme.themeName === 'DARK' ? theme.colors.black : '#425a70'};
  }
`;

export default StyledRadioGroup;
