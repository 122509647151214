import styled from 'styled-components';

const MenuItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  height: 46px;
  padding: 10px 20px;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.getTintsAndShades('gray')['100']};
  }
`;
export default MenuItem;
