import { MenuOutline, PlusCircleFill } from '@ant-design/icons';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import defaultTheme from '../../styles/themes';
import {
  generateUserIdDispatcherAction,
  signOutUserDispatcherAction
} from '../../features/User/actions';
import { generateUserId } from '../../helpers/ids';
import PopupMenu from '../Elements/PopupMenu';
import MenuItem from '../Elements/PopupMenu/MenuItem';
import { Query, QueryResult } from 'react-apollo';
import { adopt } from 'react-adopt';
import {
  USER_TEMPLATES,
  IUserTemplateQuery,
  UserTemplatesVar
} from './graphql';
import { AntIcon } from '../Elements';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const renderTemplates = (data: any, goToTemplate: Function) => {
  if (data && data.userTemplates && data.userTemplates.length) {
    return data.userTemplates.map((tem, key) => (
      <MenuItem key={key} onClick={() => goToTemplate(tem.templateId)}>
        {tem.name}
      </MenuItem>
    ));
  }
  return null;
};
const Wrapper = styled.div`
  @media ${({ theme }) => theme.breakpoints.tabletPortraitUp} {
    display: none;
  }
`;

const MobilePopupMenu = ({ history, dispatch, isAuthenticated }) => {
  const handleSignOut = () => {
    history.push('/');
    const userId = generateUserId();
    dispatch(generateUserIdDispatcherAction(userId));
    sessionStorage.clear();
    dispatch(signOutUserDispatcherAction());
  };
  const { t } = useTranslation('navmenu');

  return (
    <Wrapper>
      {isAuthenticated ? (
        <PopupMenu
          color={defaultTheme.colors.black}
          icon={MenuOutline}
          top={60}
          left={-80}
        >
          <MenuItem onClick={() => history.push('/timer/create')}>
            New Timer
          </MenuItem>
          <MenuItem
            onClick={() => history.push('/rooms/create')}
            key="create-room"
          >
            {t('create-room')}
          </MenuItem>

          <MenuItem onClick={() => history.push('/mytimers')}>
            My Timers
          </MenuItem>
          <MenuItem onClick={() => history.push('/stats')}>My Stats</MenuItem>
          <MenuItem onClick={() => history.push('/rooms')}>Rooms</MenuItem>
          <MenuItem
            className="create-template-btn-mobile"
            onClick={() => history.push('/templates/create')}
            key="create-template"
          >
            <AntIcon type={PlusCircleFill} marginRight="5px" color={'#222'} />
            {t('create-template')}
          </MenuItem>
          <MenuItem
            className="show-all-templates"
            onClick={() => history.push('/templates')}
            key="show-all-templates"
          >
            {t('show-all-templates')}
          </MenuItem>
          <MenuItem onClick={() => history.push('/account/settings')}>
            Settings
          </MenuItem>
          <MenuItem onClick={() => history.push('/voting-features')}>
            Vote for Features
          </MenuItem>
          <MenuItem onClick={() => handleSignOut()}>Sign Out</MenuItem>
        </PopupMenu>
      ) : (
        <PopupMenu
          color={defaultTheme.colors.black}
          icon={MenuOutline}
          top={60}
          left={-45}
        >
          <MenuItem onClick={() => history.push('/timer/create')}>
            New Timer
          </MenuItem>
          <MenuItem onClick={() => history.push('/login')}>Log In</MenuItem>
          <MenuItem onClick={() => history.push('/signup')}>Sign Up</MenuItem>
        </PopupMenu>
      )}
    </Wrapper>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    userId: state.auth.userId
  };
};

export default withRouter(connect(mapStateToProps)(MobilePopupMenu));
