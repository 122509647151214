import React from 'react';
import { toast } from 'react-toastify';
import { logError } from '../../utils/log';

class ErrorBoundary extends React.Component {
  state;
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    logError(error);
    console.log({ error, info });
    this.setState({ hasError: true });

    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      toast.error('Something went wrong!');
      return this.props.children;

      // return <Redirect to="/" />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
