import React from 'react';
import { Subscription, Mutation, MutationFn } from 'react-apollo';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  NOTIFICATION_SUB,
  GRANT_ACCESS,
  DELETE_NOTIFICATION
} from '../Header/graphql';
import { confirmAlert } from 'react-confirm-alert';

const notificationTile = (type: 'ACCESS_GRANTED' | 'ASK_FOR_PERMISSION') => {
  if (!type) return '';
  return type === 'ACCESS_GRANTED'
    ? 'Permission granted'
    : 'Permission request';
};

const handleAccpect = async (mutationFn, notification, deleteMutation) => {
  await mutationFn({
    variables: {
      userId: notification.requestingUser,
      roomId: notification.roomId,
      id: notification.notificationId
    }
  });
  // await deleteMutation({ variables: { id: notification.notificationId } });
  // await discardNotification(deleteMutation, notification.notificationId);
};

const discardNotification = async (mutation: MutationFn, id: string) => {
  await mutation({ variables: { id: id } });
};

const createOptions = (
  notification: any,
  acceptMutation: MutationFn,
  deleteNotification: MutationFn
) => ({
  title: notificationTile(notification.type),
  message: notification.label,
  buttons: [
    {
      label: 'Yes',
      onClick: () => {
        console.log('user is', notification);
        handleAccpect(acceptMutation, notification, deleteNotification);
        location.reload(false);
        return null;
      }
    },
    {
      label: 'No',
      onClick: () => console.log('still there')
    }
  ]
});

const shouldRefresh = (notification: any) => {
  const isInRoom = window.location.href.includes(`room/`);
  if (notification.type == 'ACCESS_GRANTED' && isInRoom) {
    toast.info(`${notification.label}`);
    window.location.reload();
  }
};
export default ({ userId }) => {
  return (
    <Mutation mutation={DELETE_NOTIFICATION}>
      {(deleteNotification: MutationFn) => (
        <Mutation mutation={GRANT_ACCESS}>
          {(grantAccess: MutationFn) => (
            <Subscription
              subscription={NOTIFICATION_SUB}
              variables={{ userId }}
            >
              {({ data, loading, error }) => {
                if (loading) {
                  return null;
                }
                if (error) {
                  return null;
                }
                if (!loading) {
                  if (
                    data &&
                    data.roomNotificationSub &&
                    data.roomNotificationSub.type === 'ASK_FOR_PERMISSION'
                  ) {
                    confirmAlert(
                      createOptions(
                        data.roomNotificationSub,
                        grantAccess,
                        deleteNotification
                      )
                    );
                    return null;
                  }
                  shouldRefresh(data.roomNotificationSub);
                  return null;
                }
              }}
            </Subscription>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};
