import React, { ExoticComponent, ReactNode } from 'react';
export const isReactFragment = (
  element:
    | JSX.Element
    | ExoticComponent<{ children?: ReactNode }>
    | { type: any }
) => {
  if (element.type) {
    return element.type === React.Fragment;
  }
  return element === React.Fragment;
};
