import React, { useEffect } from 'react';
import { ApolloConsumer } from 'react-apollo';

const Signer = ({ signInHandler }) => {
  useEffect(() => {
    signInHandler();
  }, []);

  return null;
};
const UserIdManager = () => {
  return (
    <ApolloConsumer>
      {client => (
        <Signer
          signInHandler={() =>
            client.writeData({
              data: {
                isAuthenticated: true
              }
            })
          }
        />
      )}
    </ApolloConsumer>
  );
};

export default UserIdManager;
