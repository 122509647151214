import React from 'react';
import { Query } from 'react-apollo';
import { toast } from 'react-toastify';
import {
  USER_NOTIFICATIONS,
  NOTIFICATION_SUB
} from '../../../../../../components/Header/graphql';
import { Spinner } from '../../../../../../components/Elements';
import Presentational from './Presentational';

const NotificationTab = ({ userId }) => {
  return (
    <Query
      query={USER_NOTIFICATIONS}
      fetchPolicy={'cache-and-network'}
      variables={{ userId }}
    >
      {({ data, loading, error, subscribeToMore, refetch }) => {
        if (loading) {
          return <Spinner />;
        }
        if (error) {
          toast.error(error.message);
          return <h1>{error.message}</h1>;
        }
        return (
          <Presentational
            refetch={refetch}
            notifications={data.userNotifications}
            subToMore={() =>
              subscribeToMore({
                document: NOTIFICATION_SUB,
                variables: { userId: userId },
                updateQuery: (prev, { subscriptionData }) => {
                  if (!subscriptionData.data) return [...prev];
                  const newFeedItem = subscriptionData.data.roomNotificationSub;
                  return {
                    userNotifications: [newFeedItem, ...prev.userNotifications]
                  };
                }
              })
            }
          />
        );
      }}
    </Query>
  );
};

export default NotificationTab;
