import LogRocket from 'logrocket';

export const logError = (err: any, message: string = '') => {
  const dev = window.origin.includes('localhost');
  if (!dev) {
    LogRocket.captureException(err, {
      extra: {
        message
      }
    });
  }
};
