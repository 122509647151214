import React, { useEffect } from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';

import PopupMenu from '../../Elements/PopupMenu';
import MenuItem from '../../Elements/PopupMenu/MenuItem';
import { NOTIFICATION_SUB, USER_NOTIFICATIONS } from '../graphql';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
`;

const Subscriber = ({ subscribe }: { subscribe: Function }) => {
  useEffect(() => {
    subscribe();
  }, []);

  return null;
};

const getNotifications = (query: {
  data: any;
  loading: any;
  error: any;
}): number => {
  const { data, loading, error } = query;
  if (loading || error) return 0;
  if (data && Array.isArray(data.userNotifications))
    return data.userNotifications.length;
  return 0;
};

const NotificationBadge = styled.span`
  position: relative;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.danger};
  border-radius: 50%;
  padding: 2px;
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const AccountSettingsButton = ({
  handleSignOut,
  showSideBar,
  className,
  userId,
  goToStats,
  goToVoting,
  isAdmin,
  goToAdmin
}: {
  goToAdmin;
  isAdmin: boolean;
  handleSignOut: Function;
  showSideBar: Function;
  className: string;
  userId: string;
  goToStats: Function;
  goToVoting: Function;
}) => {
  const { t } = useTranslation('navmenu');

  return (
    <Query<any, IUserIdVar> query={USER_NOTIFICATIONS} variables={{ userId }}>
      {query => {
        const { subscribeToMore } = query;

        const subcribe = () =>
          subscribeToMore({
            document: NOTIFICATION_SUB,
            variables: { userId },
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return [...prev];
              const newFeedItem = subscriptionData.data.roomNotificationSub;
              console.log({
                newArray: [newFeedItem, ...prev.userNotifications],
                length: [newFeedItem, ...prev.userNotifications].length
              });
              return {
                userNotifications: [newFeedItem, ...prev.userNotifications]
              };
            }
          });

        const notificationCount = getNotifications(query as any);

        return (
          <>
            <Subscriber subscribe={subcribe} />
            <Wrapper className={className}>
              <PopupMenu
                showAvatar={true}
                userId={userId}
                top={60}
                notificationCount={notificationCount}
              >
                <MenuItem onClick={() => showSideBar()}>
                  {t('account-menu.workspace')}{' '}
                  {notificationCount > 0 ? (
                    <NotificationBadge>{notificationCount}</NotificationBadge>
                  ) : null}
                </MenuItem>
                <MenuItem onClick={() => goToStats()}>
                  {t('account-menu.stats')}
                </MenuItem>
                <MenuItem onClick={() => goToVoting()}>
                  {t('account-menu.features')}
                </MenuItem>
                {isAdmin && (
                  <MenuItem onClick={() => goToAdmin()}>Admin panel</MenuItem>
                )}
                <MenuItem onClick={() => handleSignOut()}>
                  {t('account-menu.signout')}
                </MenuItem>
              </PopupMenu>
            </Wrapper>
          </>
        );
      }}
    </Query>
  );
};

export default AccountSettingsButton;
