import React from 'react';
import Loadable from 'react-loadable';
import { Route } from 'react-router-dom';
import { Loading } from '../components/Elements';
import CreateTimerPage from '../pages/CreateTimerPage';

const HomePage = Loadable({
  loader: () => import('../pages/HomePage' /* webpackChunkName: "HomePage" */),
  loading: () => <Loading />
});

const ConnectedUserDashboard = Loadable({
  loader: () => import('../pages/ConnectedUsersPage'),
  loading: () => <Loading />
});

const AllTasksAdminDashboard = Loadable({
  loader: () => import('../pages/AllTaskAdminDashboard'),
  loading: () => <Loading />
});

const SlackIntegrationPage = Loadable({
  loader: () => import('../pages/SlackIntegration'),
  loading: () => <Loading />
});

const UsersAdminDashboard = Loadable({
  loader: () =>
    import('../pages/UsersAdminDashboard' /* webpackChunkName: "HomePage" */),
  loading: () => <Loading />
});

const RoomDashboard = Loadable({
  loader: () =>
    import('../pages/RoomAdminDashboard' /* webpackChunkName: "HomePage" */),
  loading: () => <Loading />
});

const PrivacyPolicy = Loadable({
  loader: () => import('../pages/PrivacyPage'),
  loading: () => <Loading />
});

const NotFoundPage = Loadable({
  loader: () => import('../pages/NotFound'),
  loading: () => <Loading />
});

const RoomStatsPage = Loadable({
  loader: () => import('../pages/RoomExportedData'),
  loading: () => <Loading />
});

const RoomPage = Loadable({
  loader: () => import('../pages/RoomPage'),
  loading: () => <Loading />
});

const UserRoomListPage = Loadable({
  loader: () => import('../pages/UserRoomListPage'),
  loading: () => <Loading />
});

const RoomRedirectPage = Loadable({
  loader: () => import('../pages/RoomRedirect'),
  loading: () => <Loading />
});

const AccountSettingsPage = Loadable({
  loader: () =>
    import('../pages/AccountSettingsPage' /* webpackChunkName: "HomePage" */),
  loading: () => <Loading />
});

const ErrorRedirectPage = Loadable({
  loader: () =>
    import('../pages/ErrorRedirectPage' /* webpackChunkName: "HomePage" */),
  loading: () => <Loading />
});

const RoomTaskListPage = Loadable({
  loader: () => import('../pages/RoomTaskListPage'),
  loading: () => <Loading />
});

const RoomBoardPage = Loadable({
  loader: () => import('../pages/RoomBoardPage'),
  loading: () => <Loading />
});

const LoginPage = Loadable({
  loader: () =>
    import('../pages/LoginPage' /* webpackChunkName: "LoginPage" */),
  loading: () => <Loading />
});

const CreatePasswordPage = Loadable({
  loader: () =>
    import(
      '../pages/CreatePasswordPage' /* webpackChunkName: "CreatePasswordPage" */
    ),
  loading: () => <Loading />
});

const RoomSettings = Loadable({
  loader: () =>
    import(
      '../pages/RoomSettingsPage' /* webpackChunkName: "CreatePasswordPage" */
    ),
  loading: () => <Loading />
});

const SignUpPage = Loadable({
  loader: () =>
    import('../pages/SignUpPage' /* webpackChunkName: "SignUpPage" */),
  loading: () => <Loading />
});

const TimerPage = Loadable({
  loader: () =>
    import('../pages/TimerPage' /* webpackChunkName: "TimerPage" */),
  loading: () => <Loading />
});

const UserTimersPage = Loadable({
  loader: () =>
    import('../pages/UserTimersPage' /* webpackChunkName: "TimerPage" */),
  loading: () => <Loading />
});

const VotingFeaturesPage = Loadable({
  loader: () => import('../pages/VotingFeaturesPage'),
  loading: () => <Loading />
});

const UserVotesPage = Loadable({
  loader: () => import('../pages/UserVotesPage'),
  loading: () => <Loading />
});

const ValidateEmailPage = Loadable({
  loader: () =>
    import(
      '../pages/ValidateEmailPage' /* webpackChunkName: "ValidateEmail" */
    ),
  loading: () => <Loading />
});

const CheckYourEmailPage = Loadable({
  loader: () =>
    import(
      '../pages/CheckYourEmailPage' /* webpackChunkName: "ValidateEmail" */
    ),
  loading: () => <Loading />
});

const PricingPage = Loadable({
  loader: () =>
    import('../pages/PricingPage' /* webpackChunkName: "ValidateEmail" */),
  loading: () => <Loading />
});

const HowToUsePage = Loadable({
  loader: () =>
    import('../pages/HowToUse' /* webpackChunkName: "ValidateEmail" */),
  loading: () => <Loading />
});

const SuggestFeaturePage = Loadable({
  loader: () =>
    import(
      '../pages/SuggestFeaturePage' /* webpackChunkName: "SuggestFeature" */
    ),
  loading: () => <Loading />
});

const VotingFeatureDetailsPage = Loadable({
  loader: () =>
    import(
      '../pages/VotingFeatureDetailsPage' /* webpackChunkName: "VotingFeatureDetailsPage" */
    ),
  loading: () => <Loading />
});

const ForgotPasswordPage = Loadable({
  loader: () =>
    import(
      '../pages/ForgotPasswordPage' /* webpackChunkName: "SuggestFeature" */
    ),
  loading: () => <Loading />
});

const SocialAuthCallBackPage = Loadable({
  loader: () =>
    import(
      '../pages/SocialCallBackPage' /* webpackChunkName: "SuggestFeature" */
    ),
  loading: () => <Loading />
});

const RoomInvitationPage = Loadable({
  loader: () =>
    import('../pages/RoomInvitation' /* webpackChunkName: "SuggestFeature" */),
  loading: () => <Loading />
});

const TestPage = Loadable({
  loader: () =>
    import('../pages/TestPage' /* webpackChunkName: "SuggestFeature" */),
  loading: () => <Loading />
});

const CreateRoomPage = Loadable({
  loader: () => import('../pages/CreateRoomPage'),
  loading: () => <Loading />
});

const BannedView = Loadable({
  loader: () => import('../pages/BannedPage'),
  loading: () => <Loading />
});

const MainDashBoard = Loadable({
  loader: () => import('../pages/MainDashBoardPage'),
  loading: () => <Loading />
});

const OnlyDesktopPage = Loadable({
  loader: () => import('../pages/OnlyDesktopPage'),
  loading: () => <Loading />
});

const DownloadUnsplashPage = Loadable({
  loader: () => import('../pages/DownloadUnsplashPage'),
  loading: () => <Loading />
});

const ErrorTestPage = Loadable({
  loader: () => import('../pages/ErrorTestPage'),
  loading: () => <Loading />
});

const StatsPage = Loadable({
  loader: () => import('../pages/UserStats'),
  loading: () => <Loading />
});

const NewVotingFeature = Loadable({
  loader: () => import('../pages/VotingFeatures'),
  loading: () => <Loading />
});
const UnsplashImageSearch = Loadable({
  loader: () => import('../pages/UnsplashImageSearch'),
  loading: () => <Loading />
});

const TasksPage = Loadable({
  loader: () => import('../pages/TasksPage'),
  loading: () => <Loading />
});

const CreateTemplatePage = Loadable({
  loader: () => import('../pages/CreateTemplatePage'),
  loading: () => <Loading />
});

const AllUserTemplatesPage = Loadable({
  loader: () => import('../pages/AllUserTemplatesPage'),
  loading: () => <Loading />
});

export default [
  <Route key="create-timerPod" path="/" exact component={HomePage} />,
  <Route
    key="pricing-page"
    path="/room/settings/:id"
    component={RoomSettings}
  />,
  <Route
    key="privacy-policy"
    path="/privacy-policy"
    exact
    component={PrivacyPolicy}
  />,
  <Route
    key="create-timerpod"
    path="/timer/create/:templateId?"
    component={CreateTimerPage}
  />,
  <Route exact key="timerpage" path="/timer/:id" component={TimerPage} />,
  <Route key="login-page" path="/login" component={LoginPage} />,
  <Route
    key="create-password"
    path="/create-password/:token"
    component={CreatePasswordPage}
  />,
  <Route
    key="spcoal-callback"
    path="/social-auth-callback/:token"
    component={SocialAuthCallBackPage}
  />,
  <Route
    key="forgot-password"
    path="/forgot-password"
    component={ForgotPasswordPage}
  />,
  <Route key="sign-up" path="/signup" component={SignUpPage} />,
  <Route
    key="old-voting"
    path="/old-voting-features"
    component={VotingFeaturesPage}
  />,
  <Route key="user-vote" path="/user-votes" component={UserVotesPage} />,
  <Route key="my-timers" path="/mytimers" component={UserTimersPage} />,
  <Route
    key="check-email"
    path="/check-email"
    component={CheckYourEmailPage}
  />,
  <Route
    key="validate-email"
    path="/validate-email"
    component={ValidateEmailPage}
  />,
  <Route
    key="suggest-feature"
    path="/suggest-feature"
    component={SuggestFeaturePage}
  />,
  <Route
    key="room-task-list"
    path="/room/:id/tasks"
    component={RoomTaskListPage}
  />,
  <Route key="room-board" path="/room/:id/board" component={RoomBoardPage} />,
  <Route key="tasks-page" path="/tasks" component={TasksPage} />,
  <Route key="room-page" path="/room/:slug/:id" component={RoomPage} />,
  <Route key="room-redirect" path="/room/:id" component={RoomRedirectPage} />,
  <Route key="room-stats" path="/stats/:id" component={RoomStatsPage} />,
  <Route
    key="voting-feature"
    path="/voting/:id"
    component={VotingFeatureDetailsPage}
  />,
  <Route key="create-room" path="/rooms/create" component={CreateRoomPage} />,
  <Route key="banned-user" path="/banned-user" component={BannedView} />,
  <Route
    key="admin-room-page"
    exact
    path="/admin/rooms"
    component={RoomDashboard}
  />,
  <Route
    key="admin-page"
    exact
    path="/admin/user"
    component={UsersAdminDashboard}
  />,
  <Route key="admin-main" exact path="/admin" component={MainDashBoard} />,
  <Route key="room-list-page" path="/rooms" component={UserRoomListPage} />,
  <Route key="test-list-page" path="/test-page" component={TestPage} />,
  <Route
    key="room-invite"
    path="/room-invitation/:id"
    component={RoomInvitationPage}
  />,
  <Route
    key="error-redirect"
    path="/error-occured"
    component={ErrorRedirectPage}
  />,
  <Route
    key="account-settings"
    path="/account/settings"
    component={AccountSettingsPage}
  />,
  <Route key="pricing-page" path="/pricing" component={PricingPage} />,
  <Route key="how-to-use-page" path="/how" component={HowToUsePage} />,
  <Route key="only-desktop" path="/only/desktop" component={OnlyDesktopPage} />,
  <Route
    key="unsplash-download"
    path="/unsplash/download"
    component={DownloadUnsplashPage}
  />,
  <Route key="error-test-page" path="/error-test" component={ErrorTestPage} />,
  <Route key="user-stats-page" path="/stats" component={StatsPage} />,
  <Route
    key="new-votes"
    path="/voting-features"
    component={NewVotingFeature}
  />,
  <Route
    key="create-template"
    path="/templates/create"
    exact
    component={CreateTemplatePage}
  />,
  <Route
    key="all-templates"
    path="/templates"
    exact
    component={AllUserTemplatesPage}
  />,
  <Route
    key="unsplash-search"
    path="/unsplash/search"
    component={UnsplashImageSearch}
  />,
  <Route
    key="slack-integration"
    path="/slack"
    component={SlackIntegrationPage}
  />,
  <Route
    key="connected-users"
    path="/admin/connected-users"
    component={ConnectedUserDashboard}
  />,
  <Route
    key="admin-tasks"
    path="/admin/tasks"
    component={AllTasksAdminDashboard}
  />,
  <Route key="not-found" component={NotFoundPage} />
];
