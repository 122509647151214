import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import defaultTheme, { darkTheme } from '../styles/themes';
import { connect } from 'react-redux';

const BackgroundUpdated = ({ theme }) => {
  useEffect(() => {
    document.body.style.backgroundColor = theme.colors.white;
  }, [theme]);

  return null;
};

const UserThemeSetter = ({ children, theme }) => {
  const [userTheme, setUserTheme] = useState<any>(defaultTheme);
  useEffect(() => {
    if (theme === 'LIGHT') {
      setUserTheme(defaultTheme);
    }
    if (theme === 'DARK') {
      setUserTheme(darkTheme);
    }
  }, [theme]);

  return (
    <>
      <ThemeProvider theme={userTheme}>{children}</ThemeProvider>
      <BackgroundUpdated theme={userTheme} />
    </>
  );
};

const mapStateToProps = state => {
  const { auth } = state;
  return {
    theme:
      auth && auth.user && auth.user.settings && auth.user.settings.theme
        ? auth.user.settings.theme
        : 'LIGHT'
  };
};

export default connect(mapStateToProps)(UserThemeSetter);
