import styled from 'styled-components';

const HeaderBar = styled.header`
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 40px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.04);
  /* box-shadow: ${({ theme }) => theme.shadows.main}; */
  @media ${({ theme }) => theme.breakpoints.mobile} {
    justify-content: space-between;
    padding: 0 15px;
  }
`;

export default HeaderBar;
