import {
  EllipsisOutline,
  PlusCircleFill,
  DotChartOutline
} from '@ant-design/icons';
import { IconDefinition } from '@ant-design/icons/lib/types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AntIcon } from '../';
import ButtonContainer from './ButtonContainer';
import UserButton from './UserButton';
import { isReactFragment } from '../../../utils/react';
import { withTheme } from 'styled-components';
import useStyledTheme from '../../../hooks/useStyledTheme';

interface IMenuItemsContainerProps {
  top: number | string | undefined;
  left: number | string | undefined;
  theme: any;
}
const MenuItemsContainer = styled.div<IMenuItemsContainerProps>`
  display: block;
  position: absolute;
  border-radius: 5px;
  padding: 5px 0;
  white-space: nowrap;
  top: ${({ top }) => (top ? String(top) + 'px' : '30px')};
  left: ${({ left }) => (left ? String(left) + 'px' : '-30px')};
  border: 1px solid #fefefe;
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows.secondary};
  /* open animation */
  transform: scale(0);
  transition: 0.2s;
  transform-origin: center 0%;
  z-index: 2000;

  &.open {
    transform: scale(1);
  }
`;

const MenuContainer = styled.div`
  position: relative;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationBadge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.danger};
  border-radius: 50%;
  padding: 2px;
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const MenuButton = ({
  onClick,
  icon = EllipsisOutline,
  size,
  color
}: {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  icon?: IconDefinition;
  size: number | undefined;
  color?: string;
}) => {
  return (
    <ButtonContainer size={size} className="MenuButton" onClick={onClick}>
      {/* <Button appearance="accent"> */}
      <AntIcon type={icon} color={color ? color : '#05d8a3'} />
      {/* </Button> */}
    </ButtonContainer>
  );
};

const MenuList = ({
  children,
  isOpen,
  top,
  left
}: {
  children: JSX.Element | JSX.Element[];
  isOpen: boolean;
  top: number;
  left: number;
}) => {
  return (
    <MenuItemsContainer
      className={(isOpen ? 'open' : '') + ' MenuItemsConainer'}
      top={top}
      left={left}
    >
      {children}
    </MenuItemsContainer>
  );
};

const PopupMenu = ({
  children,
  showPlus = false,
  size,
  userId = '',
  showAvatar = false,
  top = 0,
  left = 0,
  color,
  icon,
  style = { container: {} },
  notificationCount = 0
}: {
  children: any;
  style?: any;
  size?: number;
  color?: string;
  userId?: string;
  showAvatar?: boolean;
  top?: number;
  showPlus?: boolean;
  left?: number;
  icon?: IconDefinition;
  notificationCount?: number;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const node: any = useRef(null);
  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleClick = _ => {
    setIsOpen(!isOpen);
  };

  const { container } = style;

  if (!children) return null;
  return (
    <MenuContainer ref={node} className="MenuContainer" style={container}>
      {notificationCount > 0 ? (
        <NotificationBadge className="NotificationBadge">
          {notificationCount}
        </NotificationBadge>
      ) : null}
      {showAvatar ? (
        <UserButton onClick={handleClick} userId={userId} />
      ) : showPlus ? (
        <MenuButton
          color={color}
          size={size}
          icon={icon}
          onClick={handleClick}
        />
      ) : (
        <MenuButton
          color={color}
          size={size}
          icon={icon}
          onClick={handleClick}
        />
      )}
      <MenuList isOpen={isOpen} top={top} left={left}>
        {/* Children must be of type MenuItem */}
        {React.Children.map(children, child => {
          // Here we re-use the original handler, but we add our own handler to close the pop-up on click.
          // Taken from here https://reactjs.org/docs/react-api.html#cloneelement
          if (child && isReactFragment(child)) {
            return (
              <child.type
                key={child.props.key}
                children={child.props.children}
              />
            );
          }
          if (child && child.props && child.props.onClick && child.type) {
            return (
              <child.type
                {...child.props}
                onClick={() => {
                  child.props.onClick();
                  setIsOpen(false);
                }}
              />
            );
          }
          return null;
        })}
      </MenuList>
    </MenuContainer>
  );
};

export default PopupMenu;
