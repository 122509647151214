import Component from '@reactions/component';
import React from 'react';

import { FaIcon, Heading, Pane, Paragraph, Position, SideSheet, Tab, Tablist } from '../../../../../components/Elements';
import UserEventLogsTab from '../Tabs/Events';
import NotificationTab from '../Tabs/Notification';

export default ({ userId, name }: { userId: string; name: string }) => {
  return (
    <Component initialState={{ isShown: false }}>
      {({ state, setState }) => (
        <React.Fragment>
          <SideSheet
            isShown={state.isShown}
            position={Position.LEFT}
            onCloseComplete={() => setState({ isShown: false })}
            containerProps={{
              display: 'flex',
              flex: '1',
              flexDirection: 'column'
            }}
          >
            <Pane
              zIndex={1}
              flexShrink={0}
              elevation={0}
              backgroundColor='white'
            >
              <Pane padding={16} borderBottom='muted'>
                <Heading size={600}>Title</Heading>
                <Paragraph size={400} color='muted'>
                  Optional description or sub title
                </Paragraph>
              </Pane>
              <Pane display='flex' padding={8}>
                <Tablist>
                  <Tab
                    key='exist'
                    onSelect={() => setState({ isShown: false })}
                  >
                    <FaIcon icon='times-circle' size='1.2rem' />
                  </Tab>

                  {['Notifications', 'Event Logs'].map((tab, index) => {
                    return (
                      <Tab
                        key={String(tab) + '-' + String(index)}
                        isSelected={state.selectedIndex === index}
                        onSelect={() => setState({ selectedIndex: index })}
                      >
                        {tab}
                      </Tab>
                    );
                  })}
                </Tablist>
              </Pane>
            </Pane>
            {(state.selectedIndex == 0 || !state.selectedIndex) && (
              <NotificationTab userId={userId} />
            )}
            {state.selectedIndex == 1 && (
              <UserEventLogsTab
                closeWorkspaceHandler={() => setState({ isShown: false })}
                userId={userId}
              />
            )}
          </SideSheet>
          <span
            className='specialBtn'
            onClick={() => setState({ isShown: true })}
          >
            WorkSpace
          </span>
        </React.Fragment>
      )}
    </Component>
  );
};
