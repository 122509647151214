import React from 'react';
import AntdIcon from '@ant-design/icons-react';
import defaultTheme from '../../styles/themes';

export const AntIcon = ({
  type,
  color,
  margin,
  size,
  marginLeft,
  width,
  marginRight,
  handleClick,
  customStyle,
  className
}: {
  type: any;
  color?: string;
  className?: string;
  margin?: string;
  marginLeft?: string;
  marginRight?: string;
  size?: string;
  width?: string;
  handleClick?: Function;
  customStyle?: any;
}) => (
  <AntdIcon
    onClick={_ => (handleClick ? handleClick() : {})}
    type={type}
    className={className}
    style={{
      color: color || defaultTheme.colors.black,
      fontSize: size,
      margin: margin,
      width: width,
      marginLeft: marginLeft,
      marginRight: marginRight,
      cursor: 'pointer',
      ...customStyle
    }}
  />
);
