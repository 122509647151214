import tinycolor from 'tinycolor2';

const getTint = (colorValue: number, step: number) =>
  Math.floor(colorValue + (255 - colorValue) * step);
const getShade = (colorValue: number, step: number) =>
  Math.floor(colorValue * (1 - step));

export const generatePalette = baseColor => {
  let shades: string[] = [];
  let tints: string[] = [];
  let palette = {};
  const numberOfSteps = 4;
  let accessValues = [
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900'
  ];
  const step = 0.1;

  const getColorVariationInHex = ({
    colorFunction,
    rbgColor,
    step
  }: {
    colorFunction: Function;
    rbgColor: { r: number; b: number; g: number };
    step: number;
  }) => {
    let outputColor = {};
    for (let key in rbgColor) {
      if (rbgColor.hasOwnProperty(key)) {
        outputColor[key] = colorFunction(rbgColor[key], step);
      }
    }
    return tinycolor(outputColor).toHexString();
  };

  for (let i = 1; i <= numberOfSteps; i++) {
    let rbgBase = tinycolor(baseColor).toRgb();
    tints[i - 1] = getColorVariationInHex({
      colorFunction: getTint,
      rbgColor: rbgBase,
      step: i * step
    });
    shades[i - 1] = getColorVariationInHex({
      colorFunction: getShade,
      rbgColor: rbgBase,
      step: i * step
    });
  }
  let colorpalette = [...tints.reverse(), baseColor, ...shades];
  accessValues.forEach((val, index) => {
    palette[val] = colorpalette[index];
  });

  return palette;
};

export const convertHexToRgba = (hexColor: string, opacity?: number) => {
  const color = tinycolor(hexColor);
  color.toRgbString();
  if (opacity) {
    color.setAlpha(opacity);
  }
  return color.toRgbString();
};
