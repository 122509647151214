export {
  USER_TIMERS,
  USER_ROOMS,
  USER_LOGS,
  USER_TIMERPODS,
  USER_NOTIFICATIONS,
  USER_CONTRIBUTION,
  USER_TEMPLATES,
  FOCUS_MINUTES
} from './queries';
export {
  DELETE_ROOM,
  DELETE_TIMER,
  DELETE_TIMERPOD,
  GRANT_ACCESS,
  DELETE_NOTIFICATION,
  UPDATE_USER_STATE
} from './mutation';
export { NOTIFICATION_SUB } from './sub';

export interface IUserTemplateQuery {
  userTemplates: ITemplate[];
}

export interface UserTemplatesVar {
  userId: ID;
  limit?: number;
}
