import { createAction, handleActions } from 'redux-actions';

const taskListOpenedAction = createAction("OPEN_TASK_LIST");
const taskListClosedAction = createAction("CLOSE_TASK_LIST");


export const taskListOpened = () => async (dispatch: Function) => {
  await dispatch(taskListOpenedAction());
};

export const taskListClosed = () => async (dispatch: Function) => {
  await dispatch(taskListClosedAction());
};

interface IState {
  showTaskList: boolean;
  userTaskList: any[];
  roomTaskList: any[];
}

export const initialState: IState = {
  showTaskList: false,
  userTaskList: [],
  roomTaskList: []
};


const tasksReducer = handleActions(
  {
    [taskListOpenedAction]: state => ({
      ...state,
      showTaskList: true
    }),
    [taskListClosedAction]: state => ({
      ...state,
      showTaskList: false
    }),
  },
  initialState
);

export default tasksReducer;