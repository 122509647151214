import React from 'react';
import { connect } from 'react-redux';

import Presentational from './Presentational';

const SideBarContainer = ({ userId, name }) => {
  return (
    <div style={{ display: 'none' }}>
      <Presentational userId={userId} name={name} />
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  userId: state.auth.userId,
  name: state.auth.user ? state.auth.user.name : 'Guest'
});

export default connect(mapStateToProps)(SideBarContainer);
