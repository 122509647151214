export default {
  common: {
    signup: 'Registrarse',
    'start-timer': 'Crear un timer',
    save: 'Guardar',
    goback: 'Volver',
    loading: 'Cargando',
    'updated-successfully': 'Actualizdo Exitosamente'
  },
  navmenu: {
    'rooms-link': 'Mis Salas',
    'timers-link': 'Mis Timers',
    'create-template': 'Crear Plantilla',
    'show-all-templates': 'Show All Templates',
    'tasks-link': 'Mis Tareas',
    'settings-link': 'Configuración',
    login: 'Iniciar Sesión',
    'account-menu': {
      workspace: 'Área de Trabajo',
      stats: 'Mis Estadísticas',
      features: 'Votar Mejoras',
      signout: 'Cerrar Sesión'
    }
  },
  homepage: {
    'timers-completed': 'timers completados!',
    'start-adding': 'Comienza a crear timers hoy!',
    'cta-button': 'Crear un timer',
    'intro-title': {
      'timer-to': 'Timer para ',
      with: 'en ',
      collaboration: 'colaboración'
    }
  },
  accountsettings: {
    'tabs-names': {
      general: 'General',
      appearance: 'Apariencia',
      locationtime: 'Hora y Región',
      defaults: 'Por Defecto'
    },
    general: {
      'name-input': 'Tu nombre',
      'company-input': 'Nombre de tu compañía',
      'feedback-switch': 'Mostrar botón de feeback',
      'chat-switch': 'Mostrar botón de chat',
      'disturb-mode-switch': 'Activar el modo "No Molestar"'
    },
    appearance: {
      'custom-image': 'Imagen personalizada',
      gravatar: 'Gravatar',
      initials: 'Iniciales'
    },
    locationtime: {
      'choose-language': 'Escoger Idioma',
      'choose-country': 'Escoger país',
      'choose-timezone': 'Escoger zona horaria'
    },
    defaults: {
      'timer-duration': 'Duración por defecto para nuevos timers',
      'background-image':
        'Imagen de fondo por defecto para nuevos Timers/Salas',
      'upload-image-radio': 'Subir Imagen',
      'search-for-image-radio': 'Búscar Imagen',
      'timer-rooms-music': 'Música por defecto para Timers/Salas',
      'pick-song': 'Escoger canción',
      'add-new-song': 'Añadir nueva canción'
    }
  },
  timerpod: {
    settingsmenu: {
      timer: 'Timer',
      appearance: 'Apariencia',
      sound: 'Sonido',
      archive: 'Archivar'
    },
    taskselector: {
      'assign-task': 'Asigna una tarea...'
    },
    settings: {
      title: 'Ajustes',
      tabs: {
        timer: 'Timer',
        appearance: 'Apariencia',
        sound: 'Sonido'
      }
    }
  },
  rooms: {
    userRooms: {
      'create-room': 'Crear nueva sala'
    }
  }
};
