import React from 'react';
import Divider from '@material-ui/core/Divider';
import PopupMenu from '../../Elements/PopupMenu';
import { PlusCircleFill } from '@ant-design/icons';
import styled from 'styled-components';
import { AntIcon } from '../../Elements';
import MenuItem from '../../Elements/PopupMenu/MenuItem';
import { useTranslation } from 'react-i18next';
import defaultTheme from '../../../styles/themes';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.tabletPortraitDown} {
    display: none;
  }
`;

const TemplateActions = ({
  goToRoom,
  className,
  createTemplate,
  goToCreateTimer
}: {
  goToRoom: Function;
  className: string;
  createTemplate: Function;
  goToCreateTimer: Function;
}) => {
  const { t } = useTranslation('navmenu');

  return (
    <Wrapper className={'nont-auth-template-action-btns'}>
      <PopupMenu
        showAvatar={false}
        // onClick
        icon={PlusCircleFill}
        color={defaultTheme.colors.accent}
        top={60}
      >
        <MenuItem
          className="non-auth-create-timer-btn"
          onClick={() => goToCreateTimer()}
          key="create-timer"
        >
          {t('create-timer')}
        </MenuItem>
        <MenuItem onClick={() => goToRoom()} key="create-room">
          {t('create-room')}
        </MenuItem>
        <Divider key="divider-1" />
        <MenuItem
          className="non-auth-create-template-btn"
          onClick={() => createTemplate()}
          key="create-template"
        >
          <AntIcon type={PlusCircleFill} marginRight="5px" color={'#222'} />
          {t('create-template')}
        </MenuItem>
      </PopupMenu>
    </Wrapper>
  );
};

export default TemplateActions;
