import { AxiosResponse } from 'axios';
import { createAction, handleActions } from 'redux-actions';

import { isObjectEmpty } from '../../../utils/generalUtils';
import { saveToSession } from '../../../utils/session-stoarge';
import { logError } from './../../../utils/log';

// action to dispatch by the dispatchers function
const signInUser = createAction('Login this user');
const signUpUser = createAction('Sign up user');
const signOutUser = createAction('Sign out user');
const createPassword = createAction('Create password');
const updateUser = createAction('update user');
const googleAuth = createAction('Authenticate via google');
const forgotPassword = createAction('Trigger forget password');
const getUser = createAction('Get user by their token');
const clearConfirmation = createAction('Clear Confirmation');
const clearPasswordResetting = createAction('Clear Password Resetting');
const loading = createAction('loading!');
const generateUserId = createAction('generate user id');
const updatePassword = createAction('update user password');
const error = createAction('generate error message');
const clearServerErrors = createAction('clear any previously shown error!');
const userCountryUpdated = createAction('USER_COUNTRY_UPDATED');

// dispatchers
export const clearErrorDispatcherAction = () => async (
  dispatch: Function,
  _getState
) => {
  dispatch(clearServerErrors());
};

export const signOutUserDispatcherAction = () => async (
  dispatch: Function,
  _getState
) => {
  localStorage.removeItem('state');
  sessionStorage.removeItem('x-token');
  dispatch(signOutUser());
};

export const generateUserIdDispatcherAction = (userId: string) => async (
  dispatch,
  _getState
) => {
  await dispatch(generateUserId(userId));
};

export const signUpDispatcherAction = (user: any) => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    await dispatch(loading());
    saveToSession('x-token', user.token);
    await dispatch(signUpUser(user));
  } catch (err) {
    logError(err, 'SIGN_UP_API');
    if (err.response) {
      await dispatch(error(err.response));
    } else {
      await dispatch(error({ data: { message: 'SOMETHING_WENT_WRONG' } }));
    }
  }
};

export const getUserDispatcherAction = () => async (
  dispatch: Function,
  getState: Function
) => {
  try {
    await dispatch(loading());
  } catch (err) {
    logError(err, 'GET_USER_API');
    if (err.response) {
      await dispatch(error(err.response));
    } else {
      await dispatch(error({ data: { message: 'SOMETHING_WENT_WRONG' } }));
    }
  }
};

export const createPasswordDispatcherAction = user => async (
  dispatch: Function,
  _getState: Function
) => {
  try {
    await dispatch(loading());
    saveToSession('x-token', user.token);
    await dispatch(createPassword(user));
  } catch (err) {
    logError(err, 'CREATE_PASSOWRD_API');
    if (err.response) {
      await dispatch(error(err.response));
    } else {
      await dispatch(error({ data: { message: 'SOMETHING_WENT_WRONG' } }));
    }
  }
};

export const triggerForgotPasswordAction = (userData: any) => async (
  dispatch: Function,
  _getState: Function
) => {
  try {
    await dispatch(loading());
    await dispatch(forgotPassword(userData));
  } catch (err) {
    logError(err, 'TRIGGER_PASS_API');
    if (err.response) {
      await dispatch(error(err.response));
    } else {
      await dispatch(error({ data: { message: 'SOMETHING_WENT_WRONG' } }));
    }
  }
};

export const updatePasswordAction = data => async (dispatch, _getState) => {
  await dispatch(loading());
  try {
    saveToSession('x-token', data.token);
    await dispatch(updatePassword(data));
  } catch (err) {
    logError(err, 'UPDATE_PASSWORD');
    if (err.response) {
      await dispatch(error(err.response));
    } else {
      await dispatch(error({ data: { message: 'SOMETHING_WENT_WRONG' } }));
    }
  }
};

export const signInUserDispatcherAction = (user: any) => async (
  dispatch: Function,
  _getState
) => {
  try {
    const { token } = user;
    // localStorage.setItem('x-token', token);
    saveToSession('x-token', token);
    await dispatch(signInUser(user));
  } catch (err) {
    console.log(err);

    logError(err, 'SIGN_UP_API');
    if (err.response) {
      await dispatch(error(err.response));
    } else {
      await dispatch(error({ data: { message: 'SOMETHING_WENT_WRONG' } }));
    }
  }
};

export const googleAuthDispatcherAction = (userVm: IUser) => async (
  dispatch: Function,
  _getState
) => {
  try {
    // localStorage.setItem('x-token', userVm['token']);
    console.log('daaaaaaaa', userVm);
    saveToSession('x-token', userVm['token'] || '');
    await dispatch(googleAuth(userVm));
  } catch (err) {
    logError(err, 'SIGN_UP_API');
    if (err.response) {
      await dispatch(error(err.response));
    } else {
      await dispatch(error({ data: { message: 'SOMETHING_WENT_WRONG' } }));
    }
  }
};

export const updateUserAction = (user: any) => async (dispatch: Function) => {
  try {
    await dispatch(updateUser(user));
  } catch (err) {
    logError(error, 'UPDATE_USER_API');
    console.log(err);
    await dispatch(error({ message: 'UKNOWN_ERROR_OCCURED' }));
  }
};

export const disableConfirmationAction = () => async (dispatch: Function) => {
  await dispatch(clearConfirmation());
};

export const disablePasswordAction = () => async (dispatch: Function) => {
  await dispatch(clearPasswordResetting());
};

export const updateUserCountry = (userId, country) => async (dispatch, _) => {
  await dispatch(userCountryUpdated(country));
  // updateUserSettings(userId, { location: { country } });
  // call api;
};

interface IState {
  user: any;
  isLoading: boolean;
  isLoaded: boolean;
  userId: string;
  error: any;
  isAuthenticated: boolean;
  needConfirmation: boolean;
  resetingPassword: boolean;
}

// initial state
export const initialState: IState = {
  user: { name: '' },
  isLoading: false,
  isLoaded: false,
  userId: '',
  error: {},
  isAuthenticated: false,
  needConfirmation: false,
  resetingPassword: false
};

// reducer
const userReducer = handleActions(
  {
    [loading as any]: state => ({
      ...state,
      isLoading: true,
      isLoaded: false,
      error: {}
    }),
    [signInUser as any]: (state, { payload }) => ({
      ...state,
      user: payload,
      isLoading: false,
      resetingPassword: false,
      userId: payload.userId,
      needConfirmation: false,
      isLoaded: true,
      isAuthenticated: true
    }),
    [signOutUser as any]: state => ({
      ...state,
      isAuthenticated: false,
      isLoading: false,
      needConfirmation: false,
      isLoaded: true,
      user: {}
    }),
    [clearConfirmation as any]: state => ({
      ...state,
      needConfirmation: false,
      isLoaded: true
    }),
    [clearPasswordResetting as any]: state => ({
      ...state,
      resetingPassword: false,
      isLoaded: true
    }),
    [signUpUser as any]: (state, { payload }) => ({
      ...state,
      user: payload,
      userId: payload.userId,
      isLoading: false,
      isLoaded: true,
      isAuthenticated: false,
      resetingPassword: false,
      needConfirmation: true
    }),
    [generateUserId as any]: (state, { payload }) => ({
      ...state,
      needConfirmation: false,
      userId: payload
    }),
    [createPassword as any]: (state, { payload }) => ({
      ...state,
      user: payload,
      userId: payload.userId,
      resetingPassword: false,
      isAuthenticated: true,
      isLoading: false,
      isLoaded: true
    }),
    [forgotPassword as any]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isLoaded: true,
      user: payload,
      isAuthenticated: false,
      needConfirmation: true,
      resetingPassword: true
    }),
    [getUser as any]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isLoaded: true,
      user: payload,
      userId: payload.userId,
      isAuthenticated: true,
      needConfirmation: false,
      resetingPassword: false
    }),
    [updatePassword as any]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      user: payload,
      isLoaded: true,
      userId: payload.userId,
      isAuthenticated: true,
      needConfirmation: false,
      resetingPassword: false
    }),
    [googleAuth as any]: (state, { payload }) => ({
      ...state,
      user: payload,
      isAuthenticated: true,
      userId: payload.userId
    }),
    [updateUser as any]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isLoaded: true,
      user: payload,
      userId: payload.userId,
      isAuthenticated: true
    }),
    [error as any]: (state, { payload }) => ({
      ...state,
      isAuthenticated: false,
      isLoading: false,
      isLoaded: true,
      error: payload
    }),
    [clearServerErrors as any]: state => ({ ...state, error: {} }),
    [userCountryUpdated as any]: (state, { payload }) => {
      if (!isObjectEmpty(state.user)) {
        const { user } = state;
        const { settings } = user;
        const { location } = settings;
        return {
          ...state,
          user: {
            ...user,
            settings: {
              ...settings,
              location: { ...location, country: payload }
            }
          }
        };
      }
      return { ...state };
    }
  },
  initialState
);

export default userReducer;
