export const deviceInfo = () => {
  const w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;
  return {
    device: navigator.userAgent,
    appName: navigator.appName,
    language: navigator.language,
    platform: navigator.platform,
    screen: {
      width: x,
      height: y
    }
  };
};
