import { TextInputField as EvergreenTextInputField } from 'evergreen-ui';
import styled from 'styled-components';

const TextInputField = styled(EvergreenTextInputField)`
  & > label {
    color: ${({ theme }) =>
      theme.themeName === 'DARK' ? theme.colors.black : '#425a70'};
  }
`;

export default TextInputField;
