import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

const setupDebuggers = () => {
  const dev = window.origin.includes('localhost');
  if (process.env.REACT_APP_ENV === 'production' && !dev) {
    Sentry.init({
      dsn: 'https://991c3ea647a0481aa74e39f508ede4a0@sentry.io/1399266'
    });
    LogRocket.init('ntt9ki/timerapp');
  }
};

export default setupDebuggers;
