import { Pane, Position, Tooltip } from 'evergreen-ui';
import React from 'react';

const OwnTooltip = ({ children, content, position, ...props }) => {
  const returnPosition = position => {
    switch (position) {
      case 'top':
        return Position.TOP;
      case 'bottom':
        return Position.BOTTOM;
      default:
        return Position.TOP;
    }
  };

  const tooltipPosition = returnPosition(position);

  return (
    <Tooltip {...props} content={content} position={tooltipPosition}>
      <Pane>{children}</Pane>
    </Tooltip>
  );
};

export default OwnTooltip;
