import { logError } from './log';

export const saveToSession = (key: string, value: string) => {
  try {
    sessionStorage.setItem(key, value);
  } catch (err) {
    logError(err);
    console.log('your browser does not support sessions');
    return null;
  }
};

export const getFromSession = (key: string) => {
  try {
    const val: string = sessionStorage.getItem(key) || '';
    return val;
  } catch (err) {
    logError(err);
    console.log('your browser does not support sessions');
    return null;
  }
};
