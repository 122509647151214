import React from 'react';
import ReactAvatar from 'react-avatar';
import Gravatar from 'react-gravatar';
import { connect } from 'react-redux';

import ButtonContainer from './ButtonContainer';

const UserButton = ({
  onClick = (e): void => {},
  name = '',
  userAvatar = '',
  email = '',
  userId = ''
}: {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  name: string;
  userAvatar: string;
  email: string;
  userId?: string;
}) => {
  if (userAvatar === 'gravatar') {
    return (
      <ButtonContainer className="MenuButton" onClick={onClick}>
        <Gravatar email={email} style={{ borderRadius: '50%' }} />
      </ButtonContainer>
    );
  } else {
    return (
      <ButtonContainer className="MenuButton" onClick={onClick}>
        <ReactAvatar
          name={name || 'Guest'}
          src={userAvatar || ''}
          round={true}
          size="45px"
        />
      </ButtonContainer>
    );
  }
};

const mapStateToProps = state => ({
  name: state.auth.user && (state.auth.user.name || 'Guest'),
  userAvatar:
    state.auth.user &&
    (state.auth.user.settings ? state.auth.user.settings.avatar : ''),
  email: state.auth.user && (state.auth.user.email || '')
});

export default connect(mapStateToProps)(UserButton);
