export function autoMergeLevel1(
  initialState: object,
  incomingState: object,
  debug: boolean
) {
  // we take the reduced state
  let newState = { ...incomingState }; // initialState = {timer, auth}
  // only rehydrate if inboundState exists and is an object
  if (incomingState && typeof incomingState === "object") {
    // incomingStaet exists
    Object.keys(initialState).forEach(key => {
      // we go through all the keys in our initialState
      if (incomingState[key] === undefined) {
        // if the values are different, just keep the values of the
        newState[key] = initialState[key];
      }
    });
  }
  return newState;
}
export function autoMergeLevel2(initialState: object, incomingState: object) {
  let newState = { ...incomingState };
  // only rehydrate if inboundState exists and is an object
  if (incomingState && typeof incomingState === "object") {
    Object.keys(incomingState).forEach(key => {
      if (incomingState[key] === undefined) {
        newState[key] = initialState[key];
      }
      if (
        isPlainEnoughObject(initialState[key]) &&
        Object.keys(initialState[key]).sort() !==
          Object.keys(newState[key]).sort()
      ) {
        // if object is plain enough shallow merge the new values (hence "Level2")
        newState[key] = { ...initialState[key], ...newState[key] };
        // return;
      }
    });
  }
  return newState;
}

function isPlainEnoughObject(o) {
  return o !== null && !Array.isArray(o) && typeof o === "object";
}
