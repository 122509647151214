import React, { createContext, useEffect, useState } from 'react';

import { getDomainNameTuple } from '../utils/generalUtils';

export const BrandContext = createContext<{ brandNameTuple: string[] }>({
  brandNameTuple: []
});

const ContextProvider = ({ children }) => {
  const [brandNameTuple, setBrandNameTuple] = useState<string[]>(['']);
  useEffect(() => {
    let name = getDomainNameTuple();
    setBrandNameTuple(name);
  }, []);

  return (
    <BrandContext.Provider value={{ brandNameTuple }}>
      {children}
    </BrandContext.Provider>
  );
};

export default ContextProvider;
