import styled from 'styled-components';

export const Title = styled.p`
  color: #222;
  font-size: 1rem;
  line-height: 16px;
  font-weight: 500;
  margin-left: 5px;
  word-break: break-word;
  display: inline-block;
`;

export const EventDate = styled.span`
  font-style: italic;
  font-size: 0.7rem;
  color: #4b516d;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const MainWrapper = styled.div`
  background: #d5e8da;
  overflow: auto;
  min-height: 80vh;
  /* z-index: 10; */
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const EventBox = styled.div`
  display: grid;
  border-radius: 10px;
  background: ${props => props.theme.colors.white};
  height: 80px;
  padding: 10px;
  position: relative;
  grid-gap: 5px;
  align-items: center;
  margin-bottom: 10px;
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 20px;
  justify-content: flex-start;
`;
export const Indicator = styled.span`
  font-size: 0.8rem;
  opacity: 0.7;
  margin-left: 10px;
  color: #222;
`;
export const RoomLink = styled.span`
  cursor: pointer;
  /* color: #4da6ff; */
`;

export const AvatarWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  position: absolute;
  bottom: 10%;
  right: 3%;
`;

export const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 0;
  margin: 0;
`;
