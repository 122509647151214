const query = `
  query AddSub($subVm: SubInput) {
    addSub(subVm: $subVm) {
      userId
    }
  }
`;
function sendSubscription(subscription) {
  const stateString = localStorage.getItem('state') || '';
  if (stateString) {
    const state = JSON.parse(stateString);
    const user = state.auth.userId;
    const body = JSON.stringify(subscription);
    const subId = '';
    console.log({ user });
    const subVm = { subId, body, userId: user };
    const variables = {
      subVm: subVm
    };
    console.log('vars are', { variables });
    const link =
      window.location.hostname === 'localhost'
        ? 'http://localhost:5000'
        : 'https://tmrapi.pragmatimer.com/graphql';
    return fetch(link, {
      method: 'POST',
      body: JSON.stringify({ query, variables }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  } else {
    console.log('empty local stoarge');
  }
}
const convertedVapidKey = urlBase64ToUint8Array(
  'BHK0pNZgXXbKB1tx36gl3j7TofxE0n8ljmBvw7q2x7ihiaP_qfJvVY1P5rWa3TaJqeP73OvfpIzYyj84wL50GGY'
);
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
export function subscribeUser() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(function(registration) {
        if (!registration.pushManager) {
          console.log('Push manager unavailable.');
          return;
        }
        registration.pushManager
          .getSubscription()
          .then(function(existedSubscription) {
            if (existedSubscription === null) {
              console.log('Push manager unavailable.');
              registration.pushManager
                .subscribe({
                  applicationServerKey: convertedVapidKey,
                  userVisibleOnly: true
                })
                .then(function(newSubscription) {
                  console.log('New subscription added...');
                  sendSubscription(newSubscription);
                })
                .catch(function(e) {
                  if (Notification.permission !== 'granted') {
                    console.log('Permission was not granted.');
                  } else {
                    console.error(
                      'An error ocurred during the subscription process.',
                      e
                    );
                  }
                });
            } else {
              console.log('Existed subscription detected.');
              sendSubscription(existedSubscription);
            }
          });
      })
      .catch(function(e) {
        console.error(
          'An error ocurred during Service Worker registration.',
          e
        );
      });
  }
}
