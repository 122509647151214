import CalendarHeatmapImport from 'react-calendar-heatmap';
import ReactTooltipImport from 'react-tooltip';
import Icon from '../Common/Icon';
import Checkbox from './Checkbox';
import MainLinkImport from '../Common/Link';
import ComponentElement from './Component';
import PageHeaderElement from './PageHeader';
import DrawerElement from './Spinner';
import TextFieldElement from './TextField';
import TextInputFieldElement from './TextInputField';
import RadioGroupImport from './RadioGroup';
import FormFieldImport from './FormField';
import TimerZonePickerElement from './TimeZonePicker';
import TooltipElement from './Tooltip';
export { CellWrapper, HeaderWrapper, ButtonContainer } from './TablesElements';
export { Wrapper } from './Wrapper';
export { Checkbox };
export { Button } from './Button';
export { AntIcon } from './AntIcon';
export { toast } from 'react-toastify';
export { Loading } from './Loading';
export {
  Dialog,
  Switch,
  Select,
  Textarea as TextArea,
  BackButton,
  Radio,
  SideSheet,
  Position,
  Tablist,
  Heading,
  Pane,
  Tab,
  IconButton,
  Paragraph,
  Icon,
  TagInput,
  SelectMenu,
  Badge
} from 'evergreen-ui';
export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Paragraph as NormalText,
  BasicLink
} from './TextStyles';
export { Gravatar } from './Gravatar';
export { Avatar } from './Avatar';
export { Anchor } from './Anchor';
export { Text } from './Text';
export { Spinner } from './Spinner';
export { CustomInput } from './TextInput';
export const TextInputField = TextInputFieldElement;
export const TimeZonePicker = TimerZonePickerElement;
export const Drawer = DrawerElement;
export const CalendarHeatmap = CalendarHeatmapImport;
export const TextField = TextFieldElement;
export const PageHeader = PageHeaderElement;
export const Tooltip = TooltipElement;
export const Component = ComponentElement;
// export const SelectMenu = TooltipElement;
export const ReactTooltip = ReactTooltipImport;
export const FaIcon = Icon;
export const MainLink = MainLinkImport;
export const FormField = FormFieldImport;
export const RadioGroup = RadioGroupImport;
