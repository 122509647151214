import WebFont from 'webfontloader';

const loadGoogleFonts = () => {
  WebFont.load({
    google: {
      families: ['Work Sans: 300,400,600,700,900', 'sans-serif']
    }
  });
};

export default loadGoogleFonts;
