import { MutationFn } from 'react-apollo';
import s3BrowserDirectUpload from 's3-browser-direct-upload';

import { s3Config } from '../api/env';
import { resizer } from '../features/Setting/utils';

export const uploadToS3 = (files: any): Promise<string> => {
  var s3client = new s3BrowserDirectUpload(s3Config);
  var uploadOptions = {
    data: files.data, // required
    key: `timerApp-${Date.now()}-${files.data.name}`,
    bucket: s3Config.bucketName, // required
    acl: 'public-read' // optional
  };
  return new Promise(resolve => {
    s3client.upload(uploadOptions, function(err, url) {
      resolve(url);
    });
  });
};

type Quality = { w: number; h: number; q: number };
export const uploadUnsplashToS3 = async (
  files: any,
  imgQuality: Quality | undefined,
  term
) => {
  var s3client = new s3BrowserDirectUpload(s3Config);
  const compressed = imgQuality
    ? await resizer(files, imgQuality.w, imgQuality.h, imgQuality.q)
    : files;
  var uploadOptions = {
    data: compressed, // required
    key: `${imgQuality ? term : `thumb-${term}`}-${Date.now()}-.jpg`,
    bucket: s3Config.bucketName, // required
    acl: 'public-read' // optional
  };
  return new Promise(resolve => {
    s3client.upload(uploadOptions, async function(err, url) {
      resolve(url);
    });
  });
};

export const uploadBase64ToS3 = async (
  file: any,
  catedgory: string,
  mutation: MutationFn,
  ext: any
) => {
  var s3client = new s3BrowserDirectUpload(s3Config);
  var uploadOptions = {
    data: file, // required
    key: `${catedgory}-${Date.now()}.${ext}`,
    bucket: s3Config.bucketName, // required
    acl: 'public-read' // optional
  };
  return new Promise(async resolve => {
    s3client.upload(uploadOptions, async function(err, url) {
      const imgVm = { url, term: catedgory };
      const res = await mutation({ variables: { imgVm } });
      resolve(url);
    });
  });
};

export const uploadManyToS3 = (files: any[], category: string) => {
  var s3client = new s3BrowserDirectUpload(s3Config);
  files.forEach(res => {
    var uploadOptions = {
      data: files, // required
      key: `${category}-${Date.now()}-${res.data.name}`,
      bucket: s3Config.bucketName, // required
      acl: 'public-read' // optional
    };
    return new Promise(resolve => {
      s3client.upload(uploadOptions, function(err, url) {
        resolve(url);
      });
    });
  });
};

export const unploadBlob = async (blob: Blob) => {
  var file = new File([blob], `${'file'}-${Date.now()}.jpeg`);
  var s3client = new s3BrowserDirectUpload(s3Config);
  var uploadOptions = {
    data: file,
    key: `${'file'}-${Date.now()}.jpeg`,
    bucket: s3Config.bucketName,
    acl: 'public-read' // optional
  };
  return new Promise(async resolve => {
    s3client.upload(uploadOptions, async function(err, url) {
      console.log('urlllll', url);
      resolve(url);
    });
  });
};
