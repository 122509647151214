import gql from 'graphql-tag';

export const DELETE_TIMER = gql`
  mutation DeleteTimer($timerId: ID) {
    deleteTimer(timerId: $timerId) {
      timerId
    }
  }
`;

export const UPDATE_USER_STATE = gql`
  mutation UpdateUserState($userId: ID) {
    updateUserState(userId: $userId) {
      userId
    }
  }
`;

export const DELETE_ROOM = gql`
  mutation DeleteRoom($roomId: ID) {
    deleteRoom(roomId: $roomId) {
      roomId
    }
  }
`;

export const DELETE_TIMERPOD = gql`
  mutation deleteTimerPod($timerPodId: ID!) {
    deleteTimerPod(timerPodId: $timerPodId) {
      timerPodId
    }
  }
`;

export const GRANT_ACCESS = gql`
  mutation GrantAccess($userId: ID, $roomId: ID, $id: ID) {
    grantAccess(userId: $userId, roomId: $roomId, id: $id) {
      roomId
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: ID) {
    deleteNotification(id: $id) {
      label
    }
  }
`;
