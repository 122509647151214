import {
  AppstoreOutline,
  HomeOutline,
  InboxOutline,
  SettingOutline,
  UnorderedListOutline
} from '@ant-design/icons';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { AntIcon } from '../Elements';
import NavMenuLink from './NavMenuLink';
import { useTranslation } from 'react-i18next';
import useStyledTheme from '../../hooks/useStyledTheme';

// const MainNavigationStyler = styled.div``;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media ${({ theme }) => theme.breakpoints.tabletPortraitDown} {
    display: none;
  }
`;

const NavItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  height: 100%;
  a {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  svg {
    width: 24px !important;
    height: 24px !important;
    margin: 5px !important;
  }
  p {
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.colors.black};
    font-size: 12px;
    text-align: center;
  }

  &:hover a,
  & .is-active {
    border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
    p {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.colors.black};
`;

const NavItem = ({ icon, label, to, className }) => {
  return (
    <NavItemContainer className={'NavItem ' + className}>
      <NavMenuLink activeClassName="is-active" exact to={to}>
        {icon}
        <StyledParagraph>{label}</StyledParagraph>
      </NavMenuLink>
    </NavItemContainer>
  );
};

const HomeLink = () => {
  return (
    <NavItem
      icon={<AntIcon margin="25px 25px" size="25px" type={HomeOutline} />}
      label="My Timer"
      to="/"
    />
  );
};

const RoomLink = ({ label }) => {
  const theme = useStyledTheme();
  return (
    <NavItem
      icon={
        <AntIcon
          margin="25px 25px"
          color={theme.colors.black}
          size="25px"
          type={InboxOutline}
        />
      }
      className="rooms-main-link"
      label={label}
      to="/rooms"
    />
  );
};

const TimersLink = ({ label }: { label: string }) => {
  const theme = useStyledTheme();
  return (
    <NavItem
      icon={
        <AntIcon
          color={theme.colors.black}
          margin="25px 25px"
          size="25px"
          type={AppstoreOutline}
        />
      }
      className="my-timers-navigator"
      label={label}
      to="/mytimers"
    />
  );
};

const TaskLink = ({ label }) => {
  const theme = useStyledTheme();
  return (
    <NavItem
      icon={
        <AntIcon
          margin="25px 25px"
          color={theme.colors.black}
          size="25px"
          type={UnorderedListOutline}
        />
      }
      label={label}
      to="/tasks"
    />
  );
};

const SettingsLink = ({ label }) => {
  const theme = useStyledTheme();
  return (
    <NavItem
      icon={
        <AntIcon
          margin="25px 25px"
          color={theme.colors.black}
          size="25px"
          type={SettingOutline}
        />
      }
      label={label}
      to="/account/settings"
    />
  );
};

const MainNavigation = ({
  isAuthenticated,
  userId
}: {
  isAuthenticated: boolean;
  userId: ID;
}) => {
  const { t } = useTranslation('navmenu');
  const roomLabel: string = t('rooms-link');
  const timerLabel: string = t('timers-link');
  const tasksLabel: string = t('tasks-link');
  const settingsLabel: string = t('settings-link');

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Wrapper>
      {/* <HomeLink /> */}
      <RoomLink label={roomLabel} />
      <TimersLink label={timerLabel} />
      <TaskLink label={tasksLabel} />
      <SettingsLink label={settingsLabel} />
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  userId: state.auth.userId || ''
});

export default connect(mapStateToProps)(MainNavigation);
