import React, { useEffect, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import { getFromSession, saveToSession } from '../../../utils/session-stoarge';
import { updateUserAction } from '../actions';
import { CLIENT_USER, USER_SUB } from '../graphql';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

const SaveToken = ({
  isAuthenticated,
  userId,
  dispatch
}: {
  isAuthenticated: boolean;
  userId: string;
  dispatch: Function;
}) => {
  if (!isAuthenticated) {
    return null;
  } else {
    return (
      <Query
        variables={{ userId }}
        fetchPolicy={'cache-and-network'}
        query={CLIENT_USER}
      >
        {({ data, subscribeToMore, loading, error }: QueryResult) => {
          if (loading) return null;
          if (error) return null;
          if (
            data &&
            data.userByClientId &&
            data.userByClientId.role === 'BANNED'
          ) {
            return <Redirect to={'/banned-user'} />;
          }
          if (data && data.userByClientId) {
            if (localStorage.getItem('force_refresh')) {
              localStorage.removeItem('force_refresh');
            } else {
              saveToSession(
                'x-token',
                data.userByClientId.token || getFromSession('x-token')
              );
              dispatch(updateUserAction(data.userByClientId));
            }
          }
          return (
            <Presentational
              subToMore={() =>
                subscribeToMore({
                  document: USER_SUB,
                  variables: {
                    userId: userId
                  },
                  updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;
                    const newFeedItem = subscriptionData.data.userSub;
                    console.log({ newFeedItem });
                    if (newFeedItem && newFeedItem.role === 'BANNED') {
                      toast.error('you have been banned');
                      console.log('user is banned');
                      saveToSession(
                        'x-token',
                        newFeedItem.token || getFromSession('x-token')
                      );
                    }
                    if (newFeedItem && newFeedItem.role !== 'BANNED') {
                      saveToSession(
                        'x-token',
                        newFeedItem.token || getFromSession('x-token')
                      );
                    }
                    dispatch(updateUserAction(newFeedItem));
                    saveToSession(
                      'x-token',
                      newFeedItem.token || getFromSession('x-token')
                    );
                    return {
                      ...prev,
                      userByClientId: newFeedItem
                    };
                  }
                })
              }
            />
          );
        }}
      </Query>
    );
  }
};

const Presentational = ({ subToMore }) => {
  useEffect(() => {
    subToMore();
  }, []);

  return null;
};

export default connect()(SaveToken);
